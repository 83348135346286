import { render, staticRenderFns } from "./damageRepairList.vue?vue&type=template&id=b913e754"
import script from "./damageRepairList.vue?vue&type=script&lang=js"
export * from "./damageRepairList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports