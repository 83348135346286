<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          New Defect Log
        </v-card-title>
        <v-divider></v-divider>

        <v-row>
          <v-col cols="6">
            <h3>DEFECT</h3>

            <v-text-field
              label="A/C Reg"
              dense
              outlined
              v-model="dados.reg_no"
            ></v-text-field>
            <v-text-field
              label="Defect Date"
              type="date"
              dense
              outlined
              v-model="dados.defect_date"
            ></v-text-field>
            <v-text-field
              label="Defect No"
              type="number"
              dense
              outlined
              v-model="dados.defect_no"
            ></v-text-field>
            <v-text-field
              label="Station"
              dense
              outlined
              v-model="dados.station"
            ></v-text-field>
            <v-row>
              <v-checkbox
                label="Non Routine Defect"
                v-model="dados.non_routin_defect"
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-checkbox
                label="This Defect has been deferred"
                v-model="dados.deferral"
              ></v-checkbox>
            </v-row>
            <h4>ATA</h4>
            <v-row class="px-3">
              <v-autocomplete
                dense
                outlined
                label="Capter"
                class="mr-1"
                v-model="dados.ata_chapter"
                :items="$store.state.ata"
                item-text="chapter"
                item-value="chapter"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content @click="getAtaSection(item.chapter)">
                    {{ item.chapter }} - {{ item.designation }}
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-autocomplete
                dense
                outlined
                class="ml-1"
                label="Section"
                :items="$store.state.ataSection"
                v-model="dados.ata_section"
                item-text="designation"
                item-value="designation"
              ></v-autocomplete>
            </v-row>

            <v-text-field
              label="Description"
              dense
              outlined
              v-model="dados.discrepancy"
            ></v-text-field>

            <v-autocomplete
              dense
              outlined
              class="ml-1"
              label="Defect Type"
              :items="$store.state.defectType"
              item-text="designation"
              item-value="designation"
              v-model="dados.defect_type"
            ></v-autocomplete>

            <v-row class="px-3">
              <v-text-field
                label="VPN"
                class="mr-1"
                dense
                outlined
                v-model="dados.vpn"
              ></v-text-field>
              <v-text-field
                label="SN"
                class="ml-1"
                dense
                outlined
                v-model="dados.stn"
              ></v-text-field>
            </v-row>
            <v-row class="px-3">
              <v-text-field
                label="Discovered By"
                dense
                outlined
                v-model="dados.discovered_by"
              ></v-text-field>
            </v-row>
          </v-col>
          <v-col cols="6">
            <h3>ACTION</h3>
            <v-row class="px-3">
              <v-text-field
                label="Correction Action"
                class="mx-1"
                dense
                outlined
                v-model="dados.correction_action"
              ></v-text-field>
              <v-text-field
                label="Station"
                class="mx-1"
                dense
                outlined
                v-model="dados.action_station"
              ></v-text-field>
            </v-row>
            <h4>Part Instaled</h4>
            <v-row class="px-3">
              <v-text-field
                label="VPN"
                dense
                outlined
                class="mx-1"
                v-model="dados.action_vpn"
              ></v-text-field>
              <v-text-field
                label="SN"
                dense
                outlined
                class="mx-1"
                v-model="dados.action_sn"
              ></v-text-field>
            </v-row>
            <h3>Correction Action ATA</h3>
            <v-autocomplete
              dense
              outlined
              label="Capter"
              :items="$store.state.ata"
              v-model="dados.action_ata_chapter"
              item-text="chapter"
              item-value="chapter"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content @click="getAtaSection2(item.chapter)">
                  {{ item.chapter }} - {{ item.designation }}
                </v-list-item-content>
              </template>
            </v-autocomplete>

            <v-autocomplete
              dense
              outlined
              label="Section"
              :items="$store.state.ataSection2"
              v-model="dados.action_ata_section"
              item-text="designation"
              item-value="designation"
            ></v-autocomplete>

            <v-text-field
              label="Corrected By"
              dense
              outlined
              v-model="dados.action_corrected_by"
            ></v-text-field>
            <v-text-field
              label="Rectified Date"
              type="date"
              dense
              outlined
              v-model="dados.action_rectified_date"
            ></v-text-field>
            <v-row class="px-3">
              <v-checkbox
                label="ETOPS related"
                v-model="dados.action_ethops_related"
              ></v-checkbox>
              <v-checkbox
                label="Autoland related"
                v-model="dados.action_autoland_related"
              ></v-checkbox>
              <v-checkbox
                label="Engine related"
                v-model="dados.action_engine_related"
              ></v-checkbox>
              <v-checkbox label="Rll" v-model="dados.action_rii"></v-checkbox>
            </v-row>
            <v-file-input
              dense
              outlined
              label="Link to Log"
              v-model="dados.link_to_log"
            ></v-file-input>

            <v-row class="justify-end">
              <v-btn color="info" elevation="0" @click="clear" class="ml-2"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                @click="save"
                :disabled="$store.state.permission"
                elevation="0"
                class="ml-2"
                >Save</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca
  },

  data() {
    return {
      dados: {
        internalId: null,
        reg_no: null,
        defect_date: null,
        defect_no: null,
        station: null,
        ata_section: null,
        ata_chapter: null,
        discrepancy: null,
        vpn: null,
        stn: null,
        defect_type: null,
        discovered_by: null,
        deferral: null,
        non_routin_defect: null,
        correction_action: null,
        action_station: null,
        action_vpn: null,
        action_sn: null,
        action_ata_chapter: null,
        action_ata_section: null,
        action_corrected_by: null,
        action_rectified_date: null,
        action_ethops_related: null,
        action_autoland_related: null,
        action_engine_related: null,
        action_rii: null,
        link_to_log: null
      }
    };
  },
  mounted() {
    this.$store.dispatch("permission");
    this.$store.dispatch("getAta");
    this.$store.dispatch("defectType");
  },
  methods: {
    getAtaSection(val) {
      this.$store.dispatch("ataSection", { index: val });
    },
    getAtaSection2(val) {
      this.$store.dispatch("ataSection", { index: val, d: 2 });
    },
    clear() {
      this.dados = {
        internalId: null,
        reg_no: null,
        defect_date: null,
        defect_no: null,
        station: null,
        ata_section: null,
        ata_chapter: null,
        discrepancy: null,
        vpn: null,
        stn: null,
        defect_type: null,
        discovered_by: null,
        deferral: null,
        non_routin_defect: null,
        correction_action: null,
        action_station: null,
        action_vpn: null,
        action_sn: null,
        action_ata_chapter: null,
        action_ata_section: null,
        action_corrected_by: null,
        action_rectified_date: null,
        action_ethops_related: null,
        action_autoland_related: null,
        action_engine_related: null,
        action_rii: null,
        link_to_log: null
      };
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };
      let url = this.$store.state.path + "defectLog";
      let dis = this;

      url += this.edit ? this.dados.id.toString() + "?_method=PATCH" : "";

      axios
        .post(url, this.dados, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating users",
              "error"
            );
          else {
            if (!dis.edit) {
              store.state.defectLog.push(response.data);
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New User added successfuly. "
                  : "User Updated successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New User edited successfuly. "
                  : "User Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    }
  }
};
</script>

<style></style>
