<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          <v-row class="justify-space-between">
            <h3>NEW DAMAGE and REPAIR/MODIFICATION</h3>
            <v-btn
              small
              route
              to="/damageRepairList"
              color="info"
              class="elevation-0 ma-0 mb-2"
            >
              <v-icon class="mr-3">mdi-clipboard-list</v-icon> Damage
              Repair/Modification List</v-btn
            >
          </v-row>
        </v-card-title>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="AC"
              v-model="dados.reg_no"
              dense
              outlined
              :items="$store.state.acInformation"
              item-text="reg_no"
              item-value="reg_no"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              label="ATA"
              dense
              v-model="dados.ata"
              outlined
              :items="$store.state.ata"
              item-value="chapter"
              item-text="chapter"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  {{ item.chapter }} - {{ item.designation }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="Flight Number"
              dense
              outlined
              v-model="dados.flight_no"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="">
            <v-text-field
              v-model="dados.date"
              style="date"
              type="date"
              label="*Date"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="STA"
              dense
              outlined
              v-model="dados.sta"
            ></v-text-field>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="Assigned to"
              dense
              outlined
              v-model="dados.assigned_to"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class=" ">
          <v-col class="" cols="3">
            <v-checkbox
              class=""
              v-model="dados.modif"
              labe
              :label="`Modif. ${dados.modif ? 'Yes' : 'No'}`"
            ></v-checkbox>
          </v-col>
          <v-col class="" cols="3">
            <v-checkbox
              class=""
              v-model="dados.reparation"
              :label="`Reparation ${dados.reparation ? 'Yes' : 'No'}`"
            ></v-checkbox>
          </v-col>
          <v-col class="" cols="3">
            <v-checkbox
              class=""
              v-model="dados.crack"
              :label="`Crack ${dados.crack ? 'Yes' : 'No'}`"
            ></v-checkbox>
          </v-col>
          <v-col class="" cols="3">
            <v-checkbox
              class=""
              v-model="dados.dent"
              :label="`Dent. ${dados.dent ? 'Yes' : 'No'}`"
            ></v-checkbox>
          </v-col>
        </v-row>

        <!-- ############################################################################################################################################# -->
        <v-row class="">
          <v-col cols="6" class="ma-0 pa-0">
            <v-row class="px-2"> </v-row>
            <v-row class="pl-3 pr-5 py-4">
              <v-textarea
                class="mx-2 my-0"
                label="*Descrepancy"
                rows="4"
                outlined
                v-model="dados.descrepancy"
              ></v-textarea>
            </v-row>
          </v-col>

          <v-col cols="6" class="ma-0 pa-0 b-l">
            <v-row class="pl-3 pr-5 py-4">
              <v-textarea
                class="mx-2 my-0"
                label="Corrective Action"
                rows="4"
                v-model="dados.action"
                outlined
              ></v-textarea>
            </v-row>
          </v-col>
        </v-row>

        <!-- ################################################################################################################################################# -->
        <v-row>
          <v-file-input
            label="File input"
            id="file-image"
            outlined
            dense
            v-model="dados.file"
            prepend-icon=""
            prepend-inner-icon="mdi-file"
          ></v-file-input>

          <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
          <v-btn
            color="primary"
            @click="save"
            :disabled="$store.state.permission"
            elevation="0"
            class="ml-2"
            >Save</v-btn
          >
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca
  },
  data() {
    return {
      dueDate: false,
      mel: false,
      thisLogIsForMaintenance: "",
      edit: false,
      modif: false,
      crack: false,
      reparation: false,
      dent: false,
      file: "",
      dados: {
        reg_no: "",
        flight_no: "",
        ata: "",
        sta: "",
        action: "",
        descrepancy: "",
        assigned_to: "",
        date: "",
        file: "",
        modif: "",
        crack: "",
        reparation: "",
        dent: ""
      }
    };
  },
  methods: {
    clear() {
      this.dados = {
        reg_no: "",
        flight_no: "",
        ata: "",
        sta: "",
        action: "",
        descrepancy: "",
        assigned_to: "",

        department: "",
        short_ex: "",
        date: ""
      };
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "damageRepair";
      let dis = this;

      url += this.edit ? "/" + this.dados.id.toString() + "?_method=PATCH" : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      var form_data = new FormData();

      for (var key in this.dados) {
        form_data.append(key, this.dados[key] || "");
      }
      form_data.append("file", this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating damageRepair",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch("getdamageRepair");
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New damageRepair Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New PARTS edited successfuly. "
                  : "PARTS Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    }
  },
  mounted() {
    this.$store.dispatch("permission");
    this.$store.dispatch("getAcType");
    this.$store.dispatch("getAta");
    this.$store.dispatch("getAircraftInformation");

    if (this.$store.state.editing) {
      this.dados = this.$store.state.editingItem;
      this.edit = true;
    }
  }
};
</script>

<style></style>
