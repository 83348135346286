<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          <v-row class="justify-space-between">
            <h3>ADD NEW OUT and BACK in SERVICE</h3>
            <v-btn
              small
              route
              to="/outServiceList"
              color="info"
              class="elevation-0 ma-0 mb-2"
            >
              <v-icon class="mr-3">mdi-clipboard-list</v-icon>Out and Back in
              Service List</v-btn
            >
          </v-row>
        </v-card-title>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="AC"
              v-model="dados.reg_no"
              dense
              outlined
              :items="$store.state.acInformation"
              item-text="reg_no"
              item-value="reg_no"
            >
            </v-autocomplete>
          </v-col>
          <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:150px;">
            <v-text-field
              v-model="dados.hours"
              type="time"
              value="00:00"
              label="Hours"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Date"
              type="date"
              dense
              outlined
              v-model="dados.date"
            >
            </v-text-field>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="STA"
              dense
              outlined
              v-model="dados.sta"
            ></v-text-field>
          </v-col>

          <v-col :cols="6">
            <v-row>
              <v-textarea
                label="*Reason"
                rows="4"
                outlined
                v-model="dados.reason"
              ></v-textarea>
            </v-row>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="STATUS"
              dense
              outlined
              v-model="dados.status"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-row>
              <v-textarea
                label="*Action taken"
                rows="4"
                outlined
                v-model="dados.action_taken"
              ></v-textarea>
            </v-row>
          </v-col>

          <v-col cols="6">
            <v-file-input
              dense
              outlined
              label="File"
              prepend-icon=""
              prepend-inner-icon="mdi-file"
              v-model="file"
            ></v-file-input>
          </v-col>

          <v-row class="justify-end">
            <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
            <v-btn
              color="primary"
              @click="save"
              :disabled="$store.state.permission"
              elevation="0"
              class="ml-2"
              >Save</v-btn
            >
          </v-row>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca
  },
  data() {
    return {
      dueDate: false,
      mel: false,
      thisLogIsForMaintenance: "",
      edit: false,
      file: "",
      dados: {
        reg_no: "",
        sta: "",
        reason: "",
        action_taken: "",
        hours: "",
        status: "",
        date: ""
      }
    };
  },
  methods: {
    changeCancellation() {
      this.enable = !this.enable;
    },
    clear() {
      this.dados = {
        reg_no: "",
        sta: "",
        reason: "",
        action_taken: "",
        hours: "",
        status: "",
        date: ""
      };
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "outService";
      let dis = this;

      url += this.edit ? "/" + this.dados.id.toString() + "?_method=PATCH" : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      var form_data = new FormData();

      for (var key in this.dados) {
        form_data.append(key, this.dados[key] || "");
      }
      form_data.append("file", this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating ThecnicalDelays",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch("getThecnicalDelays");
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New ThecnicalDelays Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New PARTS edited successfuly. "
                  : "PARTS Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    }
  },
  mounted() {
    this.$store.dispatch("permission");
    this.$store.dispatch("getAcType");

    this.$store.dispatch("getAircraftInformation");

    if (this.$store.state.editing) {
      this.dados = this.$store.state.editingItem;
      this.edit = true;
    }
  }
};
</script>

<style></style>
