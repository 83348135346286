<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-4 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          Cabin Defect Log
        </v-card-title>

        <v-divider></v-divider>

        <v-row class="mt-5">
          <v-col class="pa-0 px-1" cols="3">
            <v-autocomplete
              attach
              v-model="defect.aircraft_type"
              item-value="designation"
              item-text="designation"
              :items="$store.state.aircraftType"
              dense
              outlined
              label="*Aircraft Type"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content
                  @click="getAircraftConfig(item.designation)"
                >
                  {{ item.designation }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="pa-0 px-1" cols="3">
            <v-autocomplete
              attach
              v-model="defect.reg_no"
              item-value="reg_no"
              item-text="reg_no"
              :items="$store.state.aircraftConfig"
              dense
              outlined
              label="*REG NO"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content @click="getDmiRequestReg(item.reg_no)">
                  {{ item.reg_no }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="defect.flight_no"
              label="*Flight No"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pa-0 ma-0 mt-2 mx-1" style="max-width:180px;">
            <v-text-field
              v-model="defect.date"
              style="date"
              type="date"
              label="*Date"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="defect.station"
              label="*Station"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="defect.checked_by"
              label="*Checked By"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="defect.station1"
              label="*Station"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pa-0 ma-0 mt-2 mx-1" style="max-width:180px;">
            <v-text-field
              v-model="defect.date2"
              style="date"
              type="date"
              label="*Date"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-col>
          <!-- ############################################################################################################################################# -->
          <v-row class="">
            <v-col cols="6" class="ma-0 pa-0">
              <div class="info py-1 text-center white--text mb-3">
                Defect Report
              </div>
              <v-row class="px-2"> </v-row>
              <v-row class="pl-3 pr-5 py-4">
                <v-textarea
                  class="mx-2 my-0"
                  label="*Discrepancy"
                  rows="4"
                  outlined
                  v-model="defect.discrepancy"
                ></v-textarea>
              </v-row>

              <v-row class="pl-3 pr-5">
                <v-col class="pa-0 ma-0 mt-0 mx-1">
                  <v-checkbox
                    dense
                    class="ma-0"
                    v-model="defect.r_emergency_item"
                    :label="
                      `Emergency Item ${defect.r_emergency_item ? 'Yes' : 'No'}`
                    "
                  ></v-checkbox>
                </v-col>
                <v-col class="pa-0 ma-0 mt-0 mx-1">
                  <v-autocomplete
                    attach
                    v-model="defect.reported_by"
                    :items="$store.state.rep"
                    item-text="reported_by"
                    item-value="reported_by"
                    dense
                    outlined
                    label="Reported By"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="pl-5 pr-5">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.r_name"
                    label="Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.r_id_nr"
                    label="ID NR"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-3 pr-5">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.r_pn_off"
                    label="PN Off"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.r_sn_off"
                    label="SN Off"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="6" class="ma-0 pa-0 b-l">
              <div class="info py-1 text-center white--text mb-3">
                Corrective Actions
              </div>
              <v-row class="pl-3 pr-5 py-4">
                <v-textarea
                  class="mx-2 my-0"
                  label="Corrective Action"
                  rows="4"
                  v-model="defect.a_corrective_action"
                  outlined
                ></v-textarea>
              </v-row>
              <v-row class="pl-3 pr-5">
                <v-col cols="6" class="ma-0 pa-0 mt-2">
                  EMERGENCY ITEM TRANSFERED
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.a_atl"
                    label="ATL"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-5 pr-5">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.a_name"
                    label="Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.a_id_nr"
                    label="ID NR"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-3 pr-5">
                <v-col cols="4" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.a_pn_on"
                    label="PN On"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.a_sn_on"
                    label="SN On"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.a_stn"
                    label="Station"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="pl-3 pr-5">
                <v-col class="pa-0 ma-0 mt-0 mx-1">
                  <v-checkbox
                    dense
                    class="ma-0"
                    v-model="defect.a_executed"
                    :label="`Executed ${defect.a_executed ? 'Yes' : 'No'}`"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="3"
                  class="pa-0 ma-0 mt-0 mx-1"
                  style="max-width:180px;"
                >
                  <v-text-field
                    attach
                    v-if="defect.a_executed"
                    v-model="defect.ex_date"
                    style="date"
                    type="date"
                    label="*Date"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col class="pa-0 ma-0 mt-0 mx-1">
                  <v-checkbox
                    dense
                    class="ma-0"
                    v-model="defect.a_deferred"
                    :label="`Deferred ${defect.a_deferred ? 'Yes' : 'No'}`"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-text-field
                    class="mx-2"
                    v-model="defect.a_mnt_release"
                    label="MNT Release"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-autocomplete
                  attach
                  v-if="defect.a_deferred"
                  v-model="defect.a_dmi"
                  :items="$store.state.dmiRequestReg"
                  item-text="airline_dmi_no"
                  item-value="airline_dmi_no"
                  dense
                  outlined
                  label="DMI"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      {{ item.airline_dmi_no }}
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-row>
            </v-col>
          </v-row>
          <!-- ############################################################################################################################################# -->

          <!-- ################################################################################################################################################# -->
          <v-row>
            <v-file-input
              label="File input"
              id="file-image"
              outlined
              dense
              v-model="defect.file"
              prepend-icon=""
              prepend-inner-icon="mdi-file"
            ></v-file-input>
            <v-col class="pa-0 px-1" cols="3">
              <v-text-field
                label="*CDL"
                v-model="defect.cdl"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
            <v-btn
              color="primary"
              @click="save"
              :disabled="$store.state.permission"
              elevation="0"
              class="ml-2"
              >Save</v-btn
            >
          </v-row>
        </v-col>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";

export default {
  components: {
    cabeca
  },
  methods: {
    clear() {
      this.defect = {
        /* reg_number:'',  */
        file: "",
        aircraft_type: "",
        reg_no: "",
        cdl: "",
        flight_no: "",
        date: "",
        station: "",
        checked_by: "",
        station1: "",
        date2: "",
        discrepancy: "",
        r_emergency_item: "",
        r_reported_by: "",
        r_name: "",
        r_id_nr: "",
        r_pn_off: "",
        r_sn_off: "",
        a_corrective_action: "",
        a_atl: "",
        a_name: "",
        a_id_nr: "",
        a_pn_on: "",
        a_sn_on: "",
        a_stn: "",
        a_deferred: "",
        a_dmi: "",
        a_executed: "",
        a_executed_date: "",
        a_mnt_release: "",
        certificado: ""
      };

      this.defect2 = {
        /* reg_number:'',  */
        file: "",
        aircraft_type: "",
        reg_no: "",
        cdl: "",
        flight_no: "",
        date: "",
        station: "",
        checked_by: "",
        station1: "",
        date2: "",
        discrepancy: "",
        r_emergency_item: "",
        r_reported_by: "",
        r_name: "",
        r_id_nr: "",
        r_pn_off: "",
        r_sn_off: "",
        a_corrective_action: "",
        a_atl: "",
        a_name: "",
        a_id_nr: "",
        a_pn_on: "",
        a_sn_on: "",
        a_stn: "",
        a_deferred: "",
        a_dmi: "",
        a_executed: "",
        a_executed_date: "",
        a_mnt_release: "",
        certificado: ""
      };
    },

    getFile(file) {
      //const file = e.target;
      //this.$store.dispatch('loading');

      //let file_ =file.target.files[0];

      //const file = e.target.files[0];
      console.log(file);
    },

    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "cabineDefectLog";
      let dis = this;
      //let maint = true;

      url += this.edit
        ? "/" + this.defect.id.toString() + "?_method=PATCH"
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      // if((this.defect.reg_number && this.defect.fligh_number)  || (this.defect.reg_no && this.defect.fligh_number))
      var form_data = new FormData();

      for (var key in this.defect) {
        form_data.append(key, this.defect[key]);
      }
      //form_data.append('file',this.file);

      console.info("Fiormulario", form_data);

      if (dis.defect.discrepancy) {
        axios
          .post(url, form_data, config)
          .then(function(response) {
            store.dispatch("nloading");
            if (response.status == 200) {
              store.dispatch("nloading");

              if (!dis.edit) {
                store.state.cabine.push(response.data);
                //store.state.cabine.push(response.data);
                /*  store.state.acInformation = []; */

                store.dispatch("getCabine");
                if (dis.defect.discrepancy) {
                  //this.uploadStatus = { type: 'success', message: 'File uploaded successfully!' };
                  //console.log('File uploaded successfully', response.data);
                  Swal.fire(
                    "Success!",
                    dis.edit
                      ? "New defect Updated successfuly. Acraft"
                      : "defect Added successfuly. Acraft",
                    "success"
                  );
                }
              } else {
                /* store.dispatch('getAircraftInformation'); */
                //if(  dis.defect.block_time  ){
                store.state.cabine.push(response.data);
                //store.state.cabine.push(response.data);
                /*  store.state.acInformation = []; */

                store.dispatch("getCabine");
                Swal.fire(
                  "Success!",
                  dis.edit
                    ? "New defect edited successfuly. Acraft"
                    : "defect Updated successfuly. Acraft",
                  "success"
                );
              }
            } //}

            // part 2 do codigo

            if (!dis.defect.reg_no || dis.defect.reg_no) {
              dis.clear();
              return null;
            }
          })
          .catch(function(error) {
            store.dispatch("nloading");
            Swal.fire("Oops...", "Unable to save. " + error, "error");
            console.info(error);
          });
      } else {
        if (!dis.defect.discrepancy || dis.defect.discrepancy) {
          dis.clear();
          return null;
        }
      }
    },

    save2() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "defect";
      let dis = this;

      url += this.edit
        ? "/" + this.defect2.id.toString() + "?_method=PATCH"
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      //this.defect2.reg_number = this.defect.reg_number;

      var form_data = new FormData();

      for (var key in this.defect2) {
        form_data.append(key, this.defect2[key]);
      }
      form_data.append("file", this.defect.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (response.status == 200) {
            store.dispatch("nloading");

            if (!dis.edit) {
              store.state.aircraftLog.push(response.data);
              store.state.defect.push(response.data);
              /*  store.state.acInformation = []; */

              store.dispatch("getdefect");

              Swal.fire(
                "Success!",
                dis.edit
                  ? "Aircraft Log Entry 2 Updated successfuly. "
                  : "Aircraft Log Entry 2 Added successfuly.",
                "success"
              );
            } else {
              /* store.dispatch('getAircraftInformation'); */

              Swal.fire(
                "Success!",
                dis.edit
                  ? "Aircraft Log Entry 2 edited successfuly. "
                  : "Aircraft Log Entry 2 Updated successfuly.",
                "success"
              );
            }
          } else {
            Swal.fire(
              "Success!",
              dis.edit
                ? "Aircraft Log Entry 2 edited successfuly. "
                : "Aircraft Log Entry 2 Updated successfuly.",
              "success"
            );

            /* ############################################################################################ */
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire(
            "Oops...",
            "Unable to save the Aircraft Log Entry 2. " + error,
            "error"
          );
          console.info(error);
        });
    },

    getAtaSection(val) {
      this.$store.dispatch("ataSection", { index: val });
    },
    getDmiRequestReg(val) {
      this.$store.dispatch("getDmiRequestReg", val);
    },
    getAtaSection2(val) {
      this.$store.dispatch("ataSection", { index: val, d: 2 });
    },
    getAircraftConfig(val) {
      this.$store.dispatch("getAircraftConfig", val);
    }
  },
  data() {
    return {
      edit: false,

      file: "",
      uploadStatus: null,
      dados: {
        reg_no: ""
      },
      defect: {
        file: "",
        aircraft_type: "",
        reg_no: "",
        cdl: "",
        flight_no: "",
        date: "",
        station: "",
        checked_by: "",
        station1: "",
        date2: "",
        discrepancy: "",
        r_emergency_item: "",
        r_reported_by: "",
        r_name: "",
        r_id_nr: "",
        r_pn_off: "",
        r_sn_off: "",
        a_corrective_action: "",
        a_atl: "",
        a_name: "",
        a_id_nr: "",
        a_pn_on: "",
        a_sn_on: "",
        a_stn: "",
        a_deferred: "",
        a_dmi: "",
        a_executed: "",
        a_executed_date: "",
        a_mnt_release: "",
        certificado: ""
      },
      defect2: {
        file: "",
        aircraft_type: "",
        reg_no: "",
        cdl: "",
        flight_no: "",
        date: "",
        station: "",
        checked_by: "",
        station1: "",
        date2: "",
        discrepancy: "",
        r_emergency_item: "",
        r_reported_by: "",
        r_name: "",
        r_id_nr: "",
        r_pn_off: "",
        r_sn_off: "",
        a_corrective_action: "",
        a_atl: "",
        a_name: "",
        a_id_nr: "",
        a_pn_on: "",
        a_sn_on: "",
        a_stn: "",
        a_deferred: "",
        a_dmi: "",
        a_executed: "",
        a_executed_date: "",
        a_mnt_release: "",
        certificado: ""
      }
    };
  },

  mounted() {
    this.$store.dispatch("permission");
    this.$store.dispatch("getAcType");
    this.$store.dispatch("getAircraftInformation");
    this.$store.dispatch("getDmiRequestO");
    this.$store.dispatch("getReported");

    if (this.$store.state.editing) {
      let log = this.$store.state.editingItem;

      if (log.discrepancy) {
        this.defect = {
          id: log.id,
          file: "",
          aircraft_type: log.aircraft_type,

          reg_no: log.reg_no,
          cdl: log.cdl,
          flight_no: log.flight_no != "null" ? log.flight_no : null,
          date: log.date != "null" ? log.date : null,
          station: log.station != "null" ? log.station : null,
          checked_by: log.checked_by != "null" ? log.checked_by : null,
          station1: log.station1 != "null" ? log.station1 : null,
          date2: log.date2 != "null" ? log.date2 : null,
          discrepancy: log.discrepancy != "null" ? log.discrepancy : null,
          r_emergency_item:
            log.r_emergency_item != "null" ? log.r_emergency_item : null,
          r_reported_by: log.r_reported_by != "null" ? log.r_reported_by : null,
          r_name: log.r_name != "null" ? log.r_name : null,
          r_id_nr: log.r_id_nr != "null" ? log.r_id_nr : null,
          r_pn_off: log.r_pn_off != "null" ? log.r_pn_off : false,
          r_sn_off: log.r_sn_off != "null" ? log.r_sn_off : false,
          corrective_action:
            log.correction_action != "null" ? log.correction_action : null,
          a_atl: log.a_atl != "null" ? log.a_atl : null,
          a_name: log.a_name != "null" ? log.a_name : null,
          a_id_nr: log.a_id_nr != "null" ? log.a_id_nr : null,
          a_pn_on: log.a_pn_on != "null" ? log.a_pn_on : false,
          a_sn_on: log.a_sn_on != "null" ? log.a_sn_on : false,
          a_stn: log.a_stn != "null" ? log.a_stn : null,
          a_deferred: log.a_deferred != "null" ? log.a_deferred : false,
          a_dmi: log.a_dmi != "null" ? log.a_dmi : null,
          a_executed: log.a_executed != "null" ? log.a_executed : false,
          a_executed_date:
            log.a_executed_date != "null" ? log.a_executed_date : null,
          a_mnt_release: log.a_mnt_release != "null" ? log.a_mnt_release : null,
          certificado: log.checked_by != "null" ? log.checked_by : null
        };
      } else {
        this.defect = {
          id: log.id,
          aircraft_type: log.aircraft_type,
          reg_no: log.reg_no,
          cdl: log.cdl,
          flight_no: log.flight_no,
          date: log.date,
          station: log.station,
          checked_by: log.checked_by,
          station1: log.station1,
          date2: log.date2,
          discrepancy: log.discrepancy,
          r_emergency_item: log.r_emergency_item,
          r_reported_by: log.r_reported_by,
          r_name: log.r_name,
          r_id_nr: log.r_id_nr,
          r_pn_off: log.r_pn_off,
          r_sn_off: log.r_sn_off,
          a_corrective_action: log.a_corrective_action,
          a_atl: log.a_atl,
          a_name: log.a_name,
          a_id_nr: log.a_name,
          a_pn_on: log.a_pn_on,
          a_sn_on: log.a_sn_on,
          a_stn: log.a_stn,
          a_deferred: log.a_deferred,
          a_dmi: log.a_deferred,
          a_executed: log.a_executed,
          a_executed_date: log.a_executed_date,
          a_mnt_release: log.a_mnt_release,
          certificado: log.certificado
        };
        //this.log_page_number = log.log_page_number;
      }

      this.edit = true;
    }
  }
};
</script>

<style></style>
