<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          NEW MASTER LDND
        </v-card-title>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="AC"
              v-model="dados.reg_no"
              dense
              outlined
              :items="$store.state.acInformation"
              item-text="reg_no"
              item-value="reg_no"
            >
            </v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              label="ATA"
              dense
              v-model="dados.ata"
              outlined
              :items="$store.state.ata"
              item-value="chapter"
              item-text="chapter"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  {{ item.chapter }} - {{ item.designation }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="TASK NUMBER"
              dense
              outlined
              v-model="dados.task_no"
            ></v-text-field>
          </v-col>
          
          
          <v-col :cols="6">
            <v-text-field
              label="PART NUMBER"
              dense
              outlined
              v-model="dados.part_no"
            ></v-text-field>
          </v-col>
         
          
          <v-col :cols="6">
            <v-text-field
              label="SERIAL NUMBER"
              dense
              outlined
              v-model="dados.seria_no"
            ></v-text-field>
          </v-col>
          
          <v-col :cols="6">
            <v-text-field
              label="DESCRIPTION"
              dense
              outlined
              v-model="dados.description"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Mpd Task Eff."
              dense
              outlined
              v-model="dados.title"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              label="Event Type"
              v-model="dados.event_type"
              dense
              outlined
              :items="$store.state.event_type"
              item-text="event_type"
              item-value="event_type"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              label="Type Control"
              v-model="dados.control_type"
              dense
              outlined
              :items="$store.state.type_control"
              item-text="type_control"
              item-value="type_control"
            >
            </v-autocomplete>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Source"
              dense
              outlined
              v-model="dados.source"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="General Notes"
              dense
              outlined
              v-model="dados.general_note"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Side LH"
              dense
              outlined
              v-model="dados.side_lh"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Side RH"
              dense
              outlined
              v-model="dados.side_rh"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Limits Threshold"
              dense
              outlined
              v-model="dados.limits_thr"
            ></v-text-field>
          </v-col>
          

          <v-col :cols="6">
            <v-checkbox label="This Repetitive" v-model="dados.deferral">
            </v-checkbox
          ></v-col>

          <v-col :cols="6">
            <v-text-field
              attach
              v-if="!dados.deferral"
              label="NEXT DATE"
              type="date"
              dense
              outlined
              v-model="dados.next_date"
            ></v-text-field>
          </v-col>

          <v-row attach v-if="dados.deferral">
            <v-col :cols="6">
              <v-text-field
                label="THR. DAYS"
                dense
                outlined
                v-model="dados.thr_days"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="THR. FH"
                dense
                outlined
                v-model="dados.thr_fh"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="THR. FC"
                dense
                outlined
                v-model="dados.thr_fc"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="INT. DAYS"
                dense
                outlined
                v-model="dados.int_calendar"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="INT. FH"
                dense
                outlined
                v-model="dados.int_hours"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="INT. FC"
                dense
                outlined
                v-model="dados.int_cycles"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="LAST DATE"
                type="date"
                dense
                outlined
                v-model="dados.last_date"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="LAST FH"
                dense
                outlined
                v-model="dados.last_hours"
              ></v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field
                label="LAST FC"
                dense
                outlined
                v-model="dados.last_cycles"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-col cols="6">
            <v-file-input
              dense
              outlined
              label="File"
              prepend-icon=""
              prepend-inner-icon="mdi-file"
              v-model="file"
            ></v-file-input>
          </v-col>

          <v-row class="justify-end">
            <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
            <v-btn color="primary" @click="save" elevation="0" class="ml-2"
              >Save</v-btn
            >
          </v-row>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca
  },
  data() {
    return {
      dueDate: false,
      mel: false,
      thisLogIsForMaintenance: "",
      edit: false,
      file: "",
      dados: {
        reg_no: "",
        task_no: "",
        seria_no: "",
        part_no: "",
       
        description: "",
        event_type: "",
        control_type:"",
        ata: "",
        source: "",
        
        general_note: "",
        start_process: "",
        side_lh: "",
        side_rh:"",
        thr_fc:"",
        thr_fh:"",
        thr_days:"",
        limits_thr:"",
        int_calendar: "",
        int_hours: "",
        int_cycles: "",
        last_date: "",
        last_hours: "",
        last_cycles: "",
        close_date: "",
        deferral: ""
      }
    };
  },
  methods: {
    clear() {
      this.dados = {
        reg_no: "",
        task_no: "",
        seria_no: "",
        part_no: "",
        mpd_ref: "",
        position: "",
        task: "",
        ata: "",
        description: "",
        pn_comment: "",
        part_comment: "",
        nha: "",
        ref_no: "",
        docs1: "",
        docs2: "",
        int_calendar: "",
        int_hours: "",
        int_cycles: "",
        last_date: "",
        last_hours: "",
        last_cycles: "",
        close_date: "",
        deferral: ""
      };
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "masterLD";
      let dis = this;

      if (this.dados.deferral) this.dados.next_date = null;

      url += this.edit ? "/" + this.dados.id.toString() + "?_method=PATCH" : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      var form_data = new FormData();

      for (var key in this.dados) {
        form_data.append(key, this.dados[key] || "");
      }
      form_data.append("file", this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating masterLD",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch("getMasterLD");
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New masterLD Updated successfuly. "
                  : "masterLD added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New masterLD edited successfuly. "
                  : "masterLD Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    }
  },
  mounted() {
    this.$store.dispatch("getAircraftInformation");
    this.$store.dispatch("getAta");
    this.$store.dispatch("getPartType");

    if (this.$store.state.editing) {
      this.dados = this.$store.state.editingItem;
      this.edit = true;
    }
  }
};
</script>

<style></style>
