<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-4 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          ADD NEW TEARDOWN
        </v-card-title>

        <v-row>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="dados.pn"
              label="P/N"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="dados.date"
              style="date"
              type="date"
              label="*Date"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="dados.sn"
              label="S/N"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="dados.qnt"
              label="Qnt."
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="dados.status"
              label="Status"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="pa-0 ma-0 mt-2 mx-1">
            <v-text-field
              v-model="dados.supplier"
              label="Supplier"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-col>
          <!-- ############################################################################################################################################# -->
          <v-row class="">
            <v-col cols="6" class="ma-0 pa-0">
              <v-row class="px-2"> </v-row>
              <v-row class="pl-3 pr-5 py-4">
                <v-textarea
                  class="mx-2 my-0"
                  label="*Description"
                  rows="4"
                  outlined
                  v-model="dados.description"
                ></v-textarea>
              </v-row>
              <v-row class="pl-3 pr-5 py-4">
                <v-textarea
                  class="mx-2 my-0"
                  label="Removal Reason"
                  rows="4"
                  outlined
                  v-model="dados.rem_reason"
                ></v-textarea>
              </v-row>
            </v-col>

            <v-col cols="6" class="ma-0 pa-0 b-l">
              <v-row class="pl-3 pr-5 py-4">
                <v-textarea
                  class="mx-2 my-0"
                  label="Faults"
                  rows="4"
                  v-model="dados.faults"
                  outlined
                ></v-textarea>
              </v-row>
              <v-row class="pl-3 pr-5 py-4">
                <v-textarea
                  class="mx-2 my-0"
                  label="Corrective Action"
                  rows="4"
                  v-model="dados.corrective_action"
                  outlined
                ></v-textarea>
              </v-row>
            </v-col>
          </v-row>

          <!-- ################################################################################################################################################# -->
          <v-row>
            <v-file-input
              label="File input"
              id="file-image"
              outlined
              dense
              v-model="dados.file"
              prepend-icon=""
              prepend-inner-icon="mdi-file"
            ></v-file-input>

            <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
            <v-btn
              color="primary"
              @click="save"
              :disabled="$store.state.permission"
              elevation="0"
              class="ml-2"
              >Save</v-btn
            >
          </v-row>
        </v-col>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca
  },
  data() {
    return {
      dueDate: false,
      mel: false,
      thisLogIsForMaintenance: "",
      edit: false,
      file: "",
      dados: {
        pn: "",
        sn: "",
        status: "",
        qnt: "",
        description: "",
        rem_reason: "",
        faults: "",
        date: "",

        corrective_action: "",
        supplier: ""
      }
    };
  },
  methods: {
    changeCancellation() {
      this.enable = !this.enable;
    },
    clear() {
      this.dados = {
        pn: "",
        sn: "",
        status: "",
        qnt: "",
        description: "",
        rem_reason: "",
        faults: "",
        date: "",

        corrective_action: "",
        supplier: ""
      };
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "tearDown";
      let dis = this;

      url += this.edit ? "/" + this.dados.id.toString() + "?_method=PATCH" : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      var form_data = new FormData();

      for (var key in this.dados) {
        form_data.append(key, this.dados[key] || "");
      }
      form_data.append("file", this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating ThecnicalDelays",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch("getThecnicalDelays");
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New ThecnicalDelays Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New PARTS edited successfuly. "
                  : "PARTS Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    }
  },
  mounted() {
    this.$store.dispatch("permission");
    this.$store.dispatch("getAcType");

    this.$store.dispatch("getAircraftInformation");

    if (this.$store.state.editing) {
      this.dados = this.$store.state.editingItem;
      this.edit = true;
    }
  }
};
</script>

<style></style>
