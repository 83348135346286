import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index.js";
import Home from "../views/Home.vue";
import login from "../views/login.vue";
import acInformation from "../views/acInformation.vue";
import acinformationCadastro from "../views/acinformationCadastro.vue";
import atl from "../views/atl_.vue";
import newTaskCard from "../views/newTaskCard.vue";
import newLogEntry from "../views/newLogEntry.vue";
import defectLogEntry from "../views/defectLogEntry.vue";
import aircraftLog from "../views/aircraftLog.vue";
import defectLogInfoSearch from "../views/defectLogInfoSearch.vue";
import users from "../views/users.vue";
import taskCardList from "../views/taskCardList.vue";
import newDmiRequest from "../views/newDmiRequest.vue";
import newParts from "../views/newParts.vue";
import dmiRequestList from "../views/dmiRequestList.vue";
import checkPlanning from "../views/checkPlanning.vue";
import chekckPackage from "../views/chekckPackage.vue";
import checkSignOff from "../views/checkSignOff.vue";
import checkHistory from "../views/checkHistory.vue";
import tcLastDone from "../views/tcLastDone.vue";
import aircraftHoursCycles from "../views/aircraftHoursCycles.vue";
import partsList from "../views/partsList.vue";
import partInstalation from "../views/partInstalation.vue";
import partInstalationList from "../views/partInstalationList.vue";
import partRemovedList from "../views/partRemovedList.vue";
import partInstRemList from "../views/partInstRemList.vue";
import oilFuelConsuption from "../views/oilFuelConsuption.vue";
import newApu from "../views/newApu.vue";
import newApuLog from "../views/newApuLog.vue";
import apuLogList from "../views/apuLogList.vue";
import apuList from "../views/apuList.vue";
import cdefectLog from "../views/cdefectLog.vue";
import cabineDefectLog from "../views/cabineDefectLog.vue";
import cdefectLogList from "../views/cdefectLogList.vue";
import partSearch from "../views/partSearch.vue";
import newDocumments from "../views/newDocumments.vue";
import documments from "../views/documments.vue";
import newHardTime from "../views/newHardTime.vue";
import hardTimeList from "../views/hardTimeList.vue";
import hardTimeListClosed from "../views/hardTimeListClosed.vue";
import hardTimeClosed from "../views/hardTimeClosed.vue";
import newMaintenanceOrder from "../views/newMaintenanceOrder.vue";
import maintenanceOrderList from "../views/maintenanceOrderList.vue";
import maintenanceOrderClosed from "../views/maintenanceOrderClosed.vue";
import newAdEntryPage from "../views/newAdEntryPage.vue";
import adEntryPageList from "../views/adEntryPageList.vue";
import adEntryPageListClosed from "../views/adEntryPageListClosed.vue";
import adEntryPageClosed from "../views/adEntryPageClosed.vue";
import newServiceBulletin from "../views/newServiceBulletin.vue";
import serviceBulletinList from "../views/serviceBulletinList.vue";
import serviceBulletinClosed from "../views/serviceBulletinClosed.vue";
import serviceBulletinListClosed from "../views/serviceBulletinListClosed.vue";
import newTechnicalDelays from "../views/newTechnicalDelays.vue";
import technicalDelaysList from "../views/technicalDelaysList.vue";
import newOutService from "../views/newOutService.vue";
import outServiceList from "../views/outServiceList.vue";
import newTearDown from "../views/newTearDown.vue";
import tearDownList from "../views/tearDownList.vue";
import newEventsIncident from "../views/newEventsIncident.vue";
import newDamageRepair from "../views/newDamageRepair.vue";
import damageRepairList from "../views/damageRepairList.vue";
import eventsIncidentList from "../views/eventsIncidentList.vue";
import maintenanceSchedule from "../views/maintenanceSchedule.vue";
import newMaintenanceSchedule from "../views/newMaintenanceSchedule.vue";
import teste from "../views/teste.vue";

import newMasterLD from "../views/newMasterLD.vue";
import masterLDList from "../views/masterLDList.vue";
import masterLDListClosed from "../views/masterLDListClosed.vue";
import masterLDClosed from "../views/masterLDClosed.vue";
import newReportLND from "../views/newReportLND.vue";
import reportLNDList from "../views/reportLNDList.vue";
import newLLPApuTask from "../views/newLLPApuTask.vue";
import newLLPEngineTask from "../views/newLLPEngineTask.vue";
import LLPApuTaskList from "../views/LLPApuTaskList.vue";
import LLPApuTaskClosed from "../views/LLPApuTaskClosed.vue";
import LLPApuTaskListClosed from "../views/LLPApuTaskListClosed.vue";


import LLPEngineTaskList from "../views/LLPEngineTaskList.vue";
import LLPEngineTaskClosed from "../views/LLPEngineTaskClosed.vue";
import LLPEngineTaskListClosed from "../views/LLPEngineTaskListClosed.vue";

import newLLPPropellerTask from "../views/newLLPPropellerTask.vue";
import LLPPropellerTaskList from "../views/LLPPropellerTaskList.vue";
import LLPPropellerTaskClosed from "../views/LLPPropellerTaskClosed.vue";
import LLPPropellerTaskListClosed from "../views/LLPPropellerTaskListClosed.vue";

import newLLPLandingGearTask from "../views/newLLPLandingGearTask.vue";
import LLPLandingGearTaskList from "../views/LLPLandingGearTaskList.vue";
import LLPLandingGearTaskClosed from "../views/LLPLandingGearTaskClosed.vue";
import LLPLandingGearTaskListClosed from "../views/LLPLandingGearTaskListClosed.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    component: login
  },
  {
    path: "/newDocumments",
    name: "newDocumments",
    component: newDocumments
  },
  {
    path: "/documments",
    name: "documments",
    component: documments
  },
  {
    path: "/acInformation",
    name: "acInformation",
    component: acInformation
  },

  {
    path: "/acInformationCadastro",
    name: "acInformation",
    component: acinformationCadastro
  },
  {
    path: "/atl",
    name: "atl",
    component: atl
  },
  {
    path: "/newTaskCard",
    name: "newTaskCard",
    component: newTaskCard
  },
  {
    path: "/taskCardList",
    name: "taskCardList",
    component: taskCardList
  },
  {
    path: "/newApu",
    name: "newApu",
    component: newApu
  },
  {
    path: "/apuLogList",
    name: "apuLogList",
    component: apuLogList
  },
  {
    path: "/apuList",
    name: "apuList",
    component: apuList
  },
  {
    path: "/newApuLog",
    name: "newApuLog",
    component: newApuLog
  },
  {
    path: "/cabineDefectLog",
    name: "cabineDefectLog",
    component: cabineDefectLog
  },
  {
    path: "/cdefectLogList",
    name: "cdefectLogList",
    component: cdefectLogList
  },
  {
    path: "/partSearch",
    name: "partSearch",
    component: partSearch
  },

  {
    path: "/cdefectLog",
    name: "cdefectLog",
    component: cdefectLog
  },
  {
    path: "/newLogEntry",
    name: "newLogEntry",
    component: newLogEntry
  },
  {
    path: "/defectLogEntry",
    name: "defectLogEntry",
    component: defectLogEntry
  },
  {
    path: "/newHardTime",
    name: "newHardTime",
    component: newHardTime
  },
  {
    path: "/hardTimeList",
    name: "hardTimeList",
    component: hardTimeList
  },
  {
    path: "/hardTimeListClosed",
    name: "hardTimeListClosed",
    component: hardTimeListClosed
  },
  {
    path: "/hardTimeClosed",
    name: "hardTimeClosed",
    component: hardTimeClosed
  },
  {
    path: "/newMaintenanceOrder",
    name: "newMaintenanceOrder",
    component: newMaintenanceOrder
  },
  {
    path: "/maintenanceOrderList",
    name: "maintenanceOrderList",
    component: maintenanceOrderList
  },
  {
    path: "/maintenanceOrderClosed",
    name: "maintenanceOrderClosed",
    component: maintenanceOrderClosed
  },
  {
    path: "/newAdEntryPage",
    name: "newAdEntryPage",
    component: newAdEntryPage
  },
  {
    path: "/adEntryPageList",
    name: "adEntryPageList",
    component: adEntryPageList
  },
  {
    path: "/adEntryPageListClosed",
    name: "adEntryPageListClosed",
    component: adEntryPageListClosed
  },
  {
    path: "/adEntryPageClosed",
    name: "adEntryPageClosed",
    component: adEntryPageClosed
  },
  {
    path: "/newServiceBulletin",
    name: "newServiceBulletin",
    component: newServiceBulletin
  },
  {
    path: "/serviceBulletinList",
    name: "serviceBulletinList",
    component: serviceBulletinList
  },
  {
    path: "/serviceBulletinClosed",
    name: "serviceBulletinClosed",
    component: serviceBulletinClosed
  },
  {
    path: "/serviceBulletinListClosed",
    name: "serviceBulletinListClosed",
    component: serviceBulletinListClosed
  },
  {
    path: "/newTechnicalDelays",
    name: "newTechnicalDelays",
    component: newTechnicalDelays
  },
  {
    path: "/technicalDelaysList",
    name: "technicalDelaysList",
    component: technicalDelaysList
  },
  {
    path: "/newOutService",
    name: "newOutService",
    component: newOutService
  },
  {
    path: "/outServiceList",
    name: "outServiceList",
    component: outServiceList
  },
  {
    path: "/newTearDown",
    name: "newTearDown",
    component: newTearDown
  },
  {
    path: "/tearDownList",
    name: "tearDownList",
    component: tearDownList
  },
  {
    path: "/newEventsIncident",
    name: "newEventsIncident",
    component: newEventsIncident
  },
  {
    path: "/newDamageRepair",
    name: "newDamageRepair",
    component: newDamageRepair
  },
  {
    path: "/damageRepairList",
    name: "damageRepairList",
    component: damageRepairList
  },
  {
    path: "/eventsIncidentList",
    name: "eventsIncidentList",
    component: eventsIncidentList
  },
  {
    path: "/maintenanceSchedule",
    name: "maintenanceSchedule",
    component: maintenanceSchedule
  },
  {
    path: "/newMaintenanceSchedule",
    name: "newMaintenanceSchedule",
    component: newMaintenanceSchedule
  },
  {
    path: "/teste",
    name: "teste",
    component: teste
  },
  {
    path: "/newMasterLD",
    name: "newMasterLD",
    component: newMasterLD
  },
  {
    path: "/masterLDList",
    name: "masterLDList",
    component: masterLDList
  },
  {
    path: "/masterLDListClosed",
    name: "masterLDListClosed",
    component: masterLDListClosed
  },
  {
    path: "/masterLDClosed",
    name: "masterLDClosed",
    component: masterLDClosed
  },
  {
    path: "/newReportLND",
    name: "newReportLND",
    component: newReportLND
  },
  {
    path: "/reportLNDList",
    name: "reportLNDList",
    component: reportLNDList
  },
  {
    path: "/newLLPApuTask",
    name: "newLLPApuTask",
    component: newLLPApuTask
  },
  {
    path: "/newLLPEngineTask",
    name: "newLLPEngineTask",
    component: newLLPEngineTask
  },
  {
    path: "/LLPApuTaskList",
    name: "LLPApuTaskList",
    component: LLPApuTaskList
  },
  {
    path: "/LLPApuTaskClosed",
    name: "LLPApuTaskClosed",
    component: LLPApuTaskClosed
  },
  {
    path: "/LLPApuTaskListClosed",
    name: "LLPApuTaskListClosed",
    component: LLPApuTaskListClosed
  },

  {
    path: "/LLPEngineTaskList",
    name: "LLPEngineTaskList",
    component: LLPEngineTaskList
  },
  {
    path: "/LLPEngineTaskClosed",
    name: "LLPEngineTaskClosed",
    component: LLPEngineTaskClosed
  },
  {
    path: "/LLPEngineTaskListClosed",
    name: "LLPEngineTaskListClosed",
    component: LLPEngineTaskListClosed
  },

  {
    path: "/newLLPPropellerTask",
    name: "newLLPPropellerTask",
    component: newLLPPropellerTask
  },
  {
    path: "/LLPPropellerTaskList",
    name: "LLPPropellerTaskList",
    component: LLPPropellerTaskList
  },
  {
    path: "/LLPPropellerTaskClosed",
    name: "LLPPropellerTaskClosed",
    component: LLPPropellerTaskClosed
  },
  {
    path: "/LLPPropellerTaskListClosed",
    name: "LLPPropellerTaskListClosed",
    component: LLPPropellerTaskListClosed
  },
  {
    path: "/newLLPLandingGearTask",
    name: "newLLPLandingGearTask",
    component: newLLPLandingGearTask
  },
  {
    path: "/LLPLandingGearTaskList",
    name: "LLPLandingGearTaskList",
    component: LLPLandingGearTaskList
  },
  {
    path: "/LLPLandingGearTaskClosed",
    name: "LLPLandingGearTaskClosed",
    component: LLPLandingGearTaskClosed
  },
  {
    path: "/LLPLandingGearTaskListClosed",
    name: "LLPLandingGearTaskListClosed",
    component: LLPLandingGearTaskListClosed
  },
  {
    path: "/newDmiRequest",
    name: "newDmiRequest",
    component: newDmiRequest
  },
  {
    path: "/partsList",
    name: "partsList",
    component: partsList
  },
  {
    path: "/partInstalation",
    name: "partInstalation",
    component: partInstalation
  },
  {
    path: "/partRemovedList",
    name: "partRemovedList",
    component: partRemovedList
  },
  {
    path: "/partInstRemList",
    name: "partInstRemList",
    component: partInstRemList
  },
  {
    path: "/partInstalationList",
    name: "partInstalationList",
    component: partInstalationList
  },
  {
    path: "/newParts",
    name: "newParts",
    component: newParts
  },
  {
    path: "/dmiRequestList",
    name: "dmiRequestList",
    component: dmiRequestList
  },
  {
    path: "/aircraftLog",
    name: "aircraftLog",
    component: aircraftLog
  },
  {
    path: "/oilFuelConsuption",
    name: "oilFuelConsuption",
    component: oilFuelConsuption
  },
  {
    path: "/defectLogInfoSearch",
    name: "defectLogInfoSearch",
    component: defectLogInfoSearch
  },
  {
    path: "/checkPlanning",
    name: "checkPlanning",
    component: checkPlanning
  },
  {
    path: "/chekckPackage",
    name: "chekckPackage",
    component: chekckPackage
  },
  {
    path: "/checkSignOff",
    name: "checkSignOff",
    component: checkSignOff
  },
  {
    path: "/checkHistory",
    name: "checkHistory",
    component: checkHistory
  },
  {
    path: "/tcLastDone",
    name: "tcLastDone",
    component: tcLastDone
  },
  {
    path: "/aircraftHoursCycles",
    name: "aircraftHoursCycles",
    component: aircraftHoursCycles
  },
  {
    path: "/users",
    name: "users",
    component: users
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

router.afterEach(to => {
  if (
    window.sessionStorage.getItem("token") === "null" ||
    !window.sessionStorage.getItem("token")
  ) {
    store.dispatch("logout");
  } else {
    store.dispatch("login");
  }

  if (
    (to.fullPath == "/" && window.sessionStorage.getItem("token") === "") ||
    !window.sessionStorage.getItem("token")
  ) {
    router.push("/login");
  }
  if (
    to.fullPath == "/login" &&
    window.sessionStorage.getItem("token") !== "null" &&
    window.sessionStorage.getItem("token")
  ) {
    router.push("/");
  }

  if (store.state.to != to.fullPath) store.dispatch("clearEdit");
});

export default router;
