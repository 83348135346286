<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          LLP ENGINE TASK CLOSED
        </v-card-title>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="AC"
              v-model="dados.reg_no"
              dense
              outlined
              :items="$store.state.acInformation"
              item-text="reg_no"
              item-value="reg_no"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content @click="getTaskRequest(item.reg_no)">
                  {{ item.reg_no }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              label="S/N"
              dense
              v-model="dados.sn"
              outlined
              :items="$store.state.taskEngineRequest"
              item-value="sn"
              item-text="sn"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  {{ item.sn }}
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="DESCRIPTION"
              dense
              outlined
              v-model="dados.close_description"
            ></v-text-field>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="COMPLETE DATE"
              type="date"
              dense
              outlined
              v-model="dados.complete_date"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="AC Hours"
              dense
              outlined
              v-model="dados.ac_time"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="AC Cycles"
              dense
              outlined
              v-model="dados.ac_cycles"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-file-input
              dense
              outlined
              label="File"
              prepend-icon=""
              prepend-inner-icon="mdi-file"
              v-model="close_file"
            ></v-file-input>
          </v-col>

          <v-row class="justify-end">
            <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
            <v-btn color="primary" @click="save" elevation="0" class="ml-2"
              >Save</v-btn
            >
          </v-row>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca
  },
  data() {
    return {
      dueDate: false,
      mel: false,
      thisLogIsForMaintenance: "",
      edit: false,
      close_file: "",
      dados: {
        
        reg_no:"",
        pn: "",
        sn: "",
        pos: "",
        remarks:"",
        title:"",
        close_data:"",
        close_description: "",
        complete_date:"",
        ac_time:"",
        ac_cycles:""
       
        
      }
    };
  },
  methods: {
    clear() {
      this.dados = {
        
        
       
        close_description: "",
    
      };
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "LLPEngineTaskClosed";
      let dis = this;

      url += this.edit ? "/" + this.dados.id.toString() + "?_method=PATCH" : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      var form_data = new FormData();

      for (var key in this.dados) {
        form_data.append(key, this.dados[key] || "");
      }
      form_data.append("file", this.close_file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating LLPEngineTask",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch("getLLPEngineTask");
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New LLPEngineTask Updated successfuly. "
                  : "LLPEngineTask added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New LLPEngineTask edited successfuly. "
                  : "LLPEngineTask Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },

    getTaskRequest(val) {
      this.$store.dispatch("getTaskEngineRequest", val);
    }
  },
  mounted() {
    this.$store.dispatch("getAircraftInformation");
    this.$store.dispatch("getAta");
    this.$store.dispatch("getPartType");

    if (this.$store.state.editing) {
      this.dados = this.$store.state.editingItem;
      this.edit = true;
    }
  }
};
</script>

<style></style>
