<template>
  <div>
    <cabeca></cabeca>
    <partRemovedFilter :dialog="this.$store.state.dialog"></partRemovedFilter>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>Part Removed List</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>

          <v-row class="justify-end">
            <v-btn
              color="info"
              class="mr-2 mt-1"
              depressed
              small
              route
              to="/partInstalation"
              >Add New P. REM
            </v-btn>
            <v-btn
              color="primary"
              class="mr-10"
              icon
              @click="$store.state.dialog = true"
            >
              <v-icon>mdi-filter-menu</v-icon></v-btn
            >
            <v-btn color="red" icon disabled="true">
              <label for="file">
                <v-icon>mdi-file-import</v-icon>
                <input
                  @change="importExcell"
                  type="file"
                  name=""
                  id="file"
                  style="display:none;"
                />
              </label>
            </v-btn>
            <download-excel
              header="Part Removed List"
              name="partRemoved"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card flat>
          <v-data-table
            :search="search"
            fixed-header
            dense
            :headers="headers"
            :items="desserts"
            :items-per-page="5"
          >
            <template v-slot:item.options="{ item, index }">
              <v-btn
                @click="editar(item)"
                :disabled="$store.state.permission"
                small
                icon
                class="mx-2"
                color="orange"
                ><v-icon>mdi-clipboard-edit</v-icon></v-btn
              >
              <v-btn
                @click="remove(index, item)"
                :disabled="$store.state.permission"
                small
                icon
                class="mx-2"
                color="red white--text"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>
            <template v-slot:item.action_engine_related="{ item }">
              {{
                item.action_engine_related == "1" ||
                item.action_engine_related == "true"
                  ? "Yes"
                  : "No"
              }}
              <!-- <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_engine_related=='1'?true:false"></v-checkbox> -->
            </template>
            <template v-slot:item.action_ethops_related="{ item }">
              <!--  <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_ethops_related=='1'?true:false"></v-checkbox> -->
              {{
                item.action_ethops_related == "1" ||
                item.action_ethops_related == "true"
                  ? "Yes"
                  : "No"
              }}
            </template>
            <template v-slot:item.action_autoland_related="{ item }">
              {{
                item.action_autoland_related == "1" ||
                item.action_autoland_related == "true"
                  ? "Yes"
                  : "No"
              }}
              <!-- <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_autoland_related=='1'?true:false"></v-checkbox> -->
            </template>
            <template v-slot:item.action_rii="{ item }">
              {{
                item.action_rii == "1" || item.action_rii == "true"
                  ? "Yes"
                  : "No"
              }}
              <!--  <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_rii=='1'?true:false"></v-checkbox> -->
            </template>
            <!-- <template v-slot:item.deferral="{ item }">
                  {{item.deferral=='1'?'Yes':'No'}}
                </template> -->

            <template v-slot:item.certificate="{ item }">
              <v-btn
                :disabled="
                  item.file == null || item.file == 'null' ? true : false
                "
                color="success"
                small
                icon
                target="_blank"
                link
                :href="$store.state.path2 + item.file"
                ><v-icon>mdi-file-cad</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
import partRemovedFilter from "@/components/partRemovedFilter";
import readXlsxFile from "read-excel-file";

export default {
  components: {
    cabeca,
    partRemovedFilter
  },

  data() {
    return {
      search: null,
      dialog: false,
      headers: [
        {
          text: "OPTIONS",
          value: "options",
          class: "info white--text",
          width: 140
        },
        {
          text: "Uploaded File",
          align: "start",
          sortable: false,
          value: "certificate",
          class: "info white--text",
          width: 120
        },

        { text: "AC", value: "reg_no", class: "info white--text", width: 100 },
        {
          text: "ATL Nº",
          value: "atl_no",
          class: "info white--text",
          width: 130
        },

        {
          text: "REM. DATE",
          value: "rmvd_date",
          class: "info white--text",
          width: 120
        },
        /*  { text: 'ENG', value: 'action_engine_related' ,class: "info white--text", width:80,}, */

        {
          text: "REM. PART Nº",
          value: "rmvd_part_no",
          class: "info white--text",
          width: 80
        },
        {
          text: "REM. SERIAL Nº",
          value: "rmvd_serial_no",
          class: "info white--text",
          width: 80
        },
        {
          text: "REASON",
          value: "reason",
          class: "info white--text",
          width: 240
        },
        {
          text: "POSITION",
          value: "position",
          class: "info white--text",
          width: 90
        },
        {
          text: "EMP Nº",
          value: "emp_no",
          class: "info white--text",
          width: 90
        },
        {
          text: "AIRFRAME TIME ",
          value: "airframe_time",
          class: "info white--text",
          width: 140
        },
        {
          text: "CYCLES ",
          value: "cycles",
          class: "info white--text",
          width: 90
        }
      ]
    };
  },

  computed: {
    desserts() {
      return this.$store.state.partRemoved;
    }
  },

  methods: {
    editar(item) {
      this.$store.state.editing = true;
      this.$store.state.editingItem = item;
      this.$store.state.to = "/partInstalation";
      this.$router.push("partInstalation");
    },
    vedadeiro(valor) {
      return valor;
    },
    // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      store.dispatch("loading");

      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(
          this.$store.state.path + "partInstalation/" + item.id.toString()
        )
        .then(function() {
          store.dispatch("getPartInstalation");
          Swal.fire("Success!", " data removed successfuly.", "success");
        })
        .catch(function(error) {
          Swal.fire("Oops...", "Unable to delete. ", "error");
          console.info(error);
        });
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it"
      }).then(result => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    },

    async importExcell(file) {
      this.$store.dispatch("loading");

      let dis = this;

      readXlsxFile(file.target.files[0]).then(rows => {
        rows.forEach(linha => {
          try {
            const axios = require("axios");
            let form = new FormData();

            form.append("reg_no", "D2-TBK");
            /*   form.append('reg_no', 'D2-TFB') */
            /* form.append('defect_date',linha[0]||'') */

            form.append("log_page_number", linha[0] || "");
            try {
              let d = linha[1].split("/");
              form.append("defect_date", d[2] + "/" + d[1] + "/" + d[0]);
            } catch (error) {
              form.append("defect_date", "2021/01/27");
            }

            form.append("discrepancy", linha[2] || "");
            form.append("discovered_by", "");
            form.append("defect_type", linha[3] || "");
            form.append("station", linha[4] || "");
            form.append("ata_chapter", linha[6] || "");
            form.append("correction_action", linha[5] || "");
            form.append("action_corrected_by", linha[8] || "");
            if (linha[9]) {
              let d = linha[9].split("/");
              form.append(
                "action_rectified_date",
                d[2] + "/" + d[1] + "/" + d[0]
              );
            }
            form.append("vpn", linha[10] || "");
            form.append("stn", linha[11] || "");
            form.append("action_vpn", linha[12] || "");
            form.append("action_sn", linha[13] || "");
            form.append("deferral", linha[14] || "");
            form.append("file", linha[18] || "");

            form.append("ata_section", "");
            form.append("non_routin_defect", "");
            form.append("action_station", "");
            form.append("action_ata_chapter", "");
            form.append("action_ata_section", "");
            form.append("action_ethops_related", "");
            form.append("action_autoland_related", "");
            form.append("action_engine_related", "");
            form.append("action_rii", "");

            axios
              .post(dis.$store.state.path + "defectLog/", form)
              .then(response => {
                this.$store.state.taskCard.push(response.data);
              });
          } catch (error) {
            console.log(error);
          }
        });

        dis.$store.dispatch("nloading");
      });
    }
  },
  mounted() {
    /*  this.$store.dispatch('getDefectLog') */
    this.$store.dispatch("permission");
    this.$store.dispatch("getAircraftInformation");
    this.$store.dispatch("getParts");
    this.$store.dispatch("getAcType");

    this.$store.state.dialog = true;
  }
};
</script>

<style></style>
