<template>
  <div>
 <cabeca></cabeca>
 <maintenanceScheduleFilter
  :dialog="this.$store.state.dialog">
</maintenanceScheduleFilter>
 
  <v-container grid-list-xs>
      <v-card
     class="mx-auto pa-2 px-10 mb-10"
     outlined
     color="white" >

     <v-card-title class="px-0" primary-title>
         AIRCRAFT MAINTENACE SCHEDULE

         <v-row class="justify-end">
            <v-btn
              color="info"
              class="mr-2 mt-1"
              depressed
              small
              route
              to="/newMaintenanceSchedule"
              >Add New Schedule
            </v-btn>
            <v-btn
              color="primary"
              class="mr-10"
              icon
              @click="$store.state.dialog = true"
            >
              <v-icon>mdi-filter-menu</v-icon></v-btn
            >
            <download-excel
              header="Maintenance Schedule"
              name="Maintenance Schedule"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
     </v-card-title>
     
     <div class="date-filter">
      <label for="startDate">Data de Início:</label>
      <input type="date" v-model="startDate" />

      <label for="endDate">Data de Fim:</label>
      <input type="date" v-model="endDate" />
    </div>
     <div>
    
    <div  aria-label="多页">
      
      
      <div style="padding-bottom: 10px">
        <div style="height: 300px; padding-bottom: 10px">
          <XGantt
            ref="gantt3"
            header-height="55"
            alignment="center"
            
            
            :row-height="rowHeight3"
            data-index="uid"
            expand-all
            :dark="isDark3"
            :gantt-column-size="normal"
            :show-checkbox="showCheckbox3"
            :show-weekend="showWeekend3"
            :show-today="showToday3"
            :show-expand="showExpand3"
            :data="this.$store.state.maintenanceSchedule"
            :header-style="headerStyle3"
            :body-style="bodyStyle3"
            :level-color="levelColor3"
            :show-setting-btn="showSettingBtn3"
            @row-click="rowClick"
            @row-dbl-click="rowDblClick"
            @row-checked="rowChecked"
            @move-slider="moveSlider"
            @no-date-error="noDateError"
          >
            <XGanttSlider
              flat
              label="startDate"
              startDate="2024-10-01"
              date-format=""
              empty-data=""
              :move="true"
              :resize-left="false"
              :resize-right="false"
              :linked-resize="false"
              bg-color="lightgreen"
            >
            <template v-slot="{ data  }">
                <div style="display: flex; justify-content: center; height: 5px;">{{ data.description }}</div>
              </template>
            </XGanttSlider>

            <XGanttColumn label="AC" width="150" >
              <template #default="{ data }">
                <div>{{ data.name }}</div>
              </template>
            </XGanttColumn>

            
            
          </XGantt>
        </div>

        <div>total: {{ this.$store.state.maintenanceSchedule.length }}</div>

       
      
        
      </div>
    </div>

    
  </div>

     </v-card>

  </v-container> 
 </div>
</template>

<script>
import cabeca from "@/components/cabeca";
import maintenanceScheduleFilter from "@/components/maintenanceScheduleFilter";
import { ref } from 'vue';

export default {
components: {
 cabeca,
 maintenanceScheduleFilter,
},

data(){
   return{
    dateFilter:{
      start:"2024-09-01",
      end:"2024-12-10",
    },
    gantt3:ref(null),
    dialog: false,
    isMulti: false,
      changeColor: 0,
      isDark: false,
      dataList: [] ,
      rowHeight1: 30,
      showCheckbox: true,
      showWeekend: true,
      showToday: true,
      showExpand: true,
      levelColor: ['azure', 'cornsilk'],
      headerStyle: {
        bgColor: '',
        textColor: ''
      } ,
      bodyStyle: {
        textColor: '',
        todayColor: '',
        weekendColor: ''
        // hoverColor: "#f00",
        // selectColor: "#0f0"
      } ,
      colSize: 'normal',
      showSettingBtn: true,

      isDark2: false,
      dataList2: [] ,
      rowHeight2: 30,
      showCheckbox2: true,
      showWeekend2: true,
      showToday2: true,
      showExpand2: true,
      levelColor2: ['#123456', '#654321'],
      headerStyle2: {
        bgColor: '#684',
        textColor: ''
      } ,
      bodyStyle2: {
        textColor: '',
        todayColor: '',
        weekendColor: ''
        // hoverColor: "#f00",
        // selectColor: "#0f0"
      } ,
      colSize2: 'large',
      showSettingBtn2: true,

      isDark3: false,
      dataList3: [] ,
      rowHeight3: 35,
      rowHeader3: 60,
      showCheckbox3: false,
      showWeekend3: true,
      showToday3: true,
      showExpand3: true,
      levelColor3: ['', '#7A1', '#123'],
      headerStyle3: {
        bgColor: '#62697098',
        textColor: '#000'
      } ,
      bodyStyle3: {
        bgColor: 'white',
        textColor: '#000',
        todayColor: '',
        weekendColor: '',
        hoverColor: '#e2f0fd5b',
        selectColor: '#62697098'
      } ,
      colSize3: 'small',
      showSettingBtn3: false
    
   }
},
methods:{
  rowClick(data) {
    this.$store.state.editing = true;
      this.$store.state.editingItem = data;
      this.$store.state.to = "/newMaintenanceSchedule";
      this.$router.push("newMaintenanceSchedule");
      console.log('click row data:', data);

    },

    rowDblClick(data) {
      console.log('double click row data:', data);
    },

    rowChecked(state, data, list) {
      console.log('check row:', state, data, list);
    },

    // start 与 end 为旧的日期
    moveSlider(data, old) {
      console.log('move slider:', data, old);
    },

    

    noDateError(date) {
      console.log(`${date}不在范围内`);
    },

    
    
    handleMove({ level }) {
      return level !== 1;
    },

    

    
    

    handleClickReloadData3() {
      this.dataList3 = [
    {
        index: 1,
        startDate: "2020-06-05",
        endDate: "2020-08-20",
        
        name: "mydata1",
        children: []
    },
    {
        index: 2,
        startDate: "2020-07-07",
        endDate: "2020-09-11",
        ttt: {},
        name: "mydata2",
        children: [
            {
                index: 3,
                startDate: "2020-07-10",
                endDate: "2020-08-15",
                ttt: {
                    a: "aaa"
                },
                name: "child1",
                children: []
            }
        ]
    }
],
      this.dataList2 = [
        {
          uid: 1,
          startDate: '2021-11-01',
          endDate: '2021-11-10',
          name: '3号数据: reload-1',
          ttt: {
            a: 'aaa',
            b: 'bbb'
          },
          children: []
        },
        {
          uid: 2,
          startDate: '2021-11-11',
          endDate: '2021-11-20',
          name: '3号数据: reload-2',
          ttt: {
            a: 'aaa',
            b: 'bbb'
          },
          children: []
        },
        {
          uid: 3,
          startDate: '2021-11-21',
          endDate: '2021-11-30',
          name: '3号数据: reload-3',
          ttt: {
            a: 'aaa',
            b: 'bbb'
          },
          children: []
        },
        {
          uid: 4,
          startDate: '2021-12-01',
          endDate: '2021-12-10',
          name: '3号数据: reload-4',
          ttt: {
            a: 'aaa',
            b: 'bbb'
          },
          children: []
        }
      ];
    },

   
  
},
created() {
  const gantt =this.gantt3.value?.control;
  gantt.scale.startDate='2024-10-01';
  gantt.scale.endDate= '2024-12-30';
  this.dataList2 = [
    {
        index: 1,
        startDate: "2020-06-05",
        endDate: "2020-08-20",
        ttt: {
            a: "aaa",
            b: "bbb"
        },
        name: "mydata1",
        children: []
    },
    {
        index: 2,
        startDate: "2020-07-07",
        endDate: "2020-09-11",
        ttt: {},
        name: "mydata2",
        children: [
            {
                index: 3,
                startDate: "2020-07-10",
                endDate: "2020-08-15",
                ttt: {
                    a: "aaa"
                },
                name: "child1",
                children: []
            }
        ]
    }
],
    // 添加3号数据
    
    this.dataList3 = [
      {
        uid: 1,
        
        
        startDate: '2024-10-13',
          endDate: '2024-10-20',
          reg_no: 'TFB',
          name: 'TFB',
          description:'WP-7+LL+LN-50',
        children: [
        
        ]
      },
      {
        uid: 1,
        
        
        startDate: '2024-10-18',
          endDate: '2024-10-30',
          reg_no: 'TFB',
          name: 'TFB',
          description:'WP-7+LL+LN-50',
        children: [
        
        ]
      },
      
      
    ];
    //this.dataList3 =this.$store.state.maintenanceSchedule
    
  },

mounted(){
  
  this.$store.dispatch("getMaintenanceSchedule");
  this.$store.dispatch("getAircraftInformation");
  this.$store.state.dialog = false;
  this.$refs.gantt3.scale.startDate='2024-10-01';
  this.$refs.gantt3.scale.endDate= '2024-12-30';
  this.$nextTick(()=>{
    this.$refs.gantt3.scale.startDate= new Date('2024-10-01');
  this.$refs.gantt3.scale.endDate= new Date('2024-12-30');
  });
}
,
computed: {
    // Computa as tarefas filtradas com base nas datas selecionadas
    filteredTasks() {
      let filtered = this.tasks;

      // Filtra pela data de início
      if (this.startDate) {
        filtered = filtered.filter(task => new Date(task.start) >= new Date(this.startDate));
      }

      // Filtra pela data de fim
      if (this.endDate) {
        filtered = filtered.filter(task => new Date(task.end) <= new Date(this.endDate));
      }

      return filtered;
    }
  }

}
</script>