<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>Task Card List</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>

          <v-row class="justify-end">
            <v-btn
              color="info"
              class="mr-2 mt-1"
              depressed
              small
              route
              to="/newTaskCard"
              >Add New AC
            </v-btn>
            <v-btn color="primary" class="mr-10" icon>
              <v-icon>mdi-filter-menu</v-icon></v-btn
            >
            <v-btn color="red" disabled="true" icon>
              <label for="file">
                <v-icon>mdi-file-import</v-icon>
                <input
                  @change="importExcell"
                  type="file"
                  name=""
                  id="file"
                  style="display:none;"
                />
              </label>
            </v-btn>

            <download-excel
              header="Aircraft Information"
              name="Users List"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card flat>
          <v-data-table
            :search="search"
            fixed-header
            dense
            :headers="headers"
            :items="desserts"
            :items-per-page="10"
          >
            <template v-slot:item.options="{ item, index }">
              <v-btn
                @click="editar(item)"
                :disabled="$store.state.permission"
                small
                icon
                class="mx-2"
                color="orange"
                ><v-icon>mdi-clipboard-edit</v-icon></v-btn
              >
              <v-btn
                @click="remove(index, item)"
                :disabled="$store.state.permission"
                small
                icon
                class="mx-2"
                color="red white--text"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>
            <template v-slot:item.status>
              <div
                style="width:18px; height:18px;"
                class=" info rounded-circle"
              ></div>
            </template>
            <template v-slot:item.card_no="{ item }">
              <a
                color="info--text"
                small
                target="_blank"
                :href="$store.state.path2 + item.card_file_upload"
                >{{ item.card_no }}</a
              >
            </template>
            <template v-slot:item.upload="{ item }">
              <v-btn
                color="success"
                disabled
                small
                icon
                @click="upload(item.id)"
                ><v-icon>mdi-file-upload</v-icon></v-btn
              >
            </template>
            <template v-slot:item.treshould="{ item }">
              {{
                tirarBarra([
                  item.fh_t,
                  item.fc_t,
                  item.da_t,
                  item.mo_t,
                  item.ye_t
                ])
              }}
            </template>
            <template v-slot:item.repeat="{ item }">
              {{
                tirarBarra([
                  item.fh_r,
                  item.fc_r,
                  item.da_r,
                  item.mo_r,
                  item.ye_r
                ])
              }}
            </template>
            <template v-slot:item.custom="{ item }">
              {{
                tirarBarra([
                  item.fh_c,
                  item.fc_c,
                  item.da_c,
                  item.mo_c,
                  item.ye_c
                ])
              }}
            </template>
            <template v-slot:item.history="{ item }">
              <v-btn
                color="success"
                disabled
                small
                icon
                @click="history(item.id)"
                ><v-icon>mdi-cube</v-icon></v-btn
              >
            </template>
            <template v-slot:item.certificate="{ item }">
              <v-btn
                :disabled="item.certificate == null ? true : false"
                color="success"
                small
                icon
                target="_blank"
                link
                :href="$store.state.path2 + item.certificate"
                ><v-icon>mdi-file-cad</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
import readXlsxFile from "read-excel-file";
export default {
  components: {
    cabeca
  },

  data() {
    return {
      search: null,
      headers: [
        {
          text: "Options",
          value: "options",
          class: "info white--text",
          width: 140
        },
        {
          text: "MODEL",
          align: "start",
          value: "ac_model",
          class: "info white--text",
          width: 100
        },
        /*  { text: '', value: 'status',class: "info white--text", width:90,}, */
        {
          text: "CARD NO",
          value: "card_no",
          class: "info white--text",
          width: 110
        },
        {
          text: "AIRLINE TC",
          align: "center",
          value: "airline_card_no",
          class: "info white--text",
          width: 120
        },
        {
          text: "TITLE",
          value: "title",
          class: "info white--text",
          width: 300
        },
        { text: "ATA", value: "ata", class: "info white--text", width: 90 },
        {
          text: "REV DATE",
          value: "rev_date",
          class: "info white--text",
          width: 120
        },
        {
          text: "UPLOAD",
          align: "center",
          value: "upload",
          class: "info white--text",
          width: 100
        },
        {
          text: "WORK HOURS",
          value: "work_hours",
          class: "info white--text",
          width: 140
        },
        {
          text: "THRESHOULD",
          value: "treshould",
          class: "info white--text",
          width: 140
        },
        {
          text: "REPEAT",
          value: "repeat",
          class: "info white--text",
          width: 140
        },
        {
          text: "CUSTOM",
          value: "custom",
          class: "info white--text",
          width: 150
        },
        {
          text: "NOTES",
          value: "notes",
          class: "info white--text",
          width: 200
        },
        {
          text: "TYPE",
          value: "tc_type",
          class: "info white--text",
          width: 140
        },
        {
          text: "LOCATION",
          value: "location_type",
          class: "info white--text",
          width: 140
        },
        {
          text: "HISTORY",
          value: "history",
          class: "info white--text",
          width: 140
        },
        {
          text: "PACKAGE",
          value: "package",
          class: "info white--text",
          width: 140
        }
      ]
    };
  },

  computed: {
    desserts() {
      return this.$store.state.taskCard;
    }
  },

  methods: {
    tirarBarra(items) {
      let final = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i]) {
          final.push(items[i]);
        }
      }

      let f = final
        .join("/")
        .split(",")
        .join("");
      /*  console.info("final", f); */
      return f;
    },
    // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(this.$store.state.path + "acInformation/" + item.id.toString())
        .then(function() {
          store.state.acInformation.splice(index, 1);
          Swal.fire("Success!", " data removed successfuly.", "success");
        })
        .catch(function(error) {
          Swal.fire("Oops...", "Unable to delete. ", "error");
          console.info(error);
        });
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it"
      }).then(result => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    },
    editar(item) {
      this.$store.state.editing = true;
      this.$store.state.editingItem = item;
      this.$store.state.to = "/acInformationCadastro";
      this.$router.push("acInformationCadastro");
    },
    async importExcell(file) {
      this.$store.dispatch("loading");

      let dis = this;

      var dd = 0;

      readXlsxFile(file.target.files[0]).then(rows => {
        rows.forEach(linha => {
          if (dd >= 2000) return;
          if (dd >= 2000 && dd < 3000) {
            setTimeout(() => {
              try {
                const axios = require("axios");
                let form = new FormData();

                form.append("card_no", linha[2]);
                form.append("airline_card_no", linha[2]);
                form.append("ata", linha[7]);
                form.append("rev_date", linha[4]);
                form.append("title", linha[3]);
                /* form.append('fh_t',linha[5]) */
                form.append("notes", linha[21]);
                form.append("ac_model", dis.getCardModel(linha[1]));
                /*  form.append('fc_t',linha[6])
                form.append('da_t',linha[4]) */

                axios
                  .post(dis.$store.state.path + "taskCard/", form)
                  .then(response => {
                    this.$store.state.taskCard.push(response.data);
                  });
              } catch (error) {
                console.log(error);
              }
            }, 200);
          }

          dd += 1;
        });

        /*  console.info("Arquivo Excell",rows[5]); */
        dis.$store.dispatch("nloading");
      });

      /*  const ExcelJS = require('exceljs/dist/exceljs');
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.read(file.target.files[0]).then((response)=>{
      console.log(response)

      });  */
      /* s */
    },

    getCardModel(val) {
      let n = "";

      switch (val) {
        case 1:
          n = "B777";
          break;
        case 2:
          n = "B737NG";
          break;
        case 3:
          n = "B737CL";
          break;
        case 4:
          n = "B747CL";
          break;
        case 99:
          n = "FLEET";
          break;

        default:
          break;
      }
      return n;
    }
  },

  mounted() {
    this.$store.dispatch("getTaskCard");
    this.$store.dispatch("permission");
  }
};
</script>

<style></style>
