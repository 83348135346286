<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="px-0" primary-title>
          ADD NEW LLP ENGINE TASK
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="">
         
          <v-col cols="6">
            <v-autocomplete
              label="Reg Number"
              v-model="dados.reg_no"
              dense
              outlined
              :items="$store.state.acInformation"
              item-text="reg_no"
              item-value="reg_no"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          class="text-center justify-center info white--text align-center mb-2"
        >
          <p class="ma-0 py-1">Engine 1</p>
        </v-row>
        <v-row>
          

          <v-col :cols="6">
            <v-text-field
              label="Part Number"
              dense
              outlined
              v-model="dados.pn"
            ></v-text-field>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="Serial Number"
              dense
              outlined
              v-model="dados.sn"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Description"
              dense
              outlined
              v-model="dados.description"
            ></v-text-field>
          </v-col>

          
          <v-col :cols="6">
            <v-text-field
              label="Title"
              dense
              outlined
              v-model="dados.title"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Remarks"
              dense
              outlined
              v-model="dados.remarks"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Cycles Limit"
              dense
              outlined
              v-model="dados.cycles_limit"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Cycles Hours"
              dense
              outlined
              v-model="dados.hours_limit"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="text-center justify-center info white--text align-center mb-2"
        >
          <p class="ma-0 py-1">Engine 2</p>
        </v-row>
        <v-row>
          

          <v-col :cols="6">
            <v-text-field
              label="Part Number"
              dense
              outlined
              v-model="dados_2.pn"
            ></v-text-field>
          </v-col>

          <v-col :cols="6">
            <v-text-field
              label="Serial Number"
              dense
              outlined
              v-model="dados_2.sn"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Description"
              dense
              outlined
              v-model="dados_2.description"
            ></v-text-field>
          </v-col>

          
          <v-col :cols="6">
            <v-text-field
              label="Title"
              dense
              outlined
              v-model="dados_2.title"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Remarks"
              dense
              outlined
              v-model="dados_2.remarks"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Cycles Limit"
              dense
              outlined
              v-model="dados_2.cycles_limit"
            ></v-text-field>
          </v-col>
          <v-col :cols="6">
            <v-text-field
              label="Cycles Hours"
              dense
              outlined
              v-model="dados_2.hours_limit"
            ></v-text-field>
          </v-col>
        </v-row>
          <v-col cols="12">
            <v-file-input
              dense
              outlined
              label="File"
              prepend-icon=""
              prepend-inner-icon="mdi-file"
              v-model="file"
            ></v-file-input>
          </v-col>

          <v-row class="justify-end">
            <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
            <v-btn
              color="primary"
              @click="save"
              :disabled="$store.state.permission"
              elevation="0"
              class="ml-2"
              >Save</v-btn
            >
          </v-row>
        
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca
  },
  data() {
    return {
      dueDate: false,
      mel: false,
      thisLogIsForMaintenance: "",
      edit: false,
      file:"",

      dados: {
        reg_no: "",
        pn: "",
        sn: "",
        pos: "#1",
        remarks:"",
        title:"",
        hours_limit:"",
        description:"",
        rem_cycles:"",
        rem_hours:"",
        date:"", 
      },
      dados_2: {
        reg_no: "",
        pn: "",
        sn: "",
        pos: "#2",
        remarks:"",
        title:"",
        hours_limit:"",
        description:"",
        rem_cycles:"",
        rem_hours:"",
        date:"", 
      }
    };
  },
  methods: {
    clear() {
      this.dados= {
        reg_no: "",
        pn: "",
        sn: "",
        pos: "",
        remarks:"",
        title:"",
        hours_limit:"",
        description:""
        
      }
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "LLPEngineTask";
      let dis = this;

      url += this.edit ? "/" + this.dados.id.toString() + "?_method=PATCH" : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      var form_data = new FormData();

      for (var key in this.dados) {
        form_data.append(key, this.dados[key] || "");
      }
      form_data.append("file", this.file);
      if(dis.dados.sn || dis.dados.hours_limit){
        
      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating LLP ENGINE Task",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch("getLLPEngineTask");
              
              
                dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New LLP ENGINE Task Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );

            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New LLP ENGINE Task edited successfuly. "
                  : "LLPEngineTask Updated successfuly.",
                "success"
              );
            }
            //if(dis.dados_2.sn || dis.dados_2.hours_limit)
              
                //dis.save2();
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });}
        else{
          if(dis.dados_2.sn || dis.dados_2.hours_limit)
          
            dis.save2();
        }
    },
    // Funcao para salver users na bd
    save2() {
      let store = this.$store;
      store.dispatch("loading");
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "LLPEngineTask";
      let dis = this;

      url += this.edit ? "/" + this.dados_2.id.toString() + "?_method=PATCH" : "";

      this.dados_2.reg_no =this.dados.reg_no;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };

      var form_data = new FormData();

      for (var key in this.dados_2) {
        form_data.append(key, this.dados_2[key] || "");
      }
      form_data.append("file", this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading");
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating LLP ENGINE Task 2",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch("getLLPEngineTask");
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New LLP ENGINE Task 2 Updated successfuly. "
                  : "PARTS added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New LLP ENGINE Task 2 edited successfuly. "
                  : "LLPEngineTask Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading");
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    }
  },
  mounted() {
    this.$store.dispatch("permission");
    this.$store.dispatch("getAcType");
    this.$store.dispatch("getAircraftInformation");
    this.$store.dispatch("getApu");

    if (this.$store.state.editing) {
      this.dados = this.$store.state.editingItem;
      this.edit = true;
    }
  }
};
</script>

<style></style>
