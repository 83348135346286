<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">TearDown Search</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  label="P/N"
                  dense
                  outlined
                  v-model="dados.pn"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  label="S/N"
                  dense
                  outlined
                  v-model="dados.sn"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Date From (GMT)"
                  type="date"
                  dense
                  outlined
                  v-model="dados.from"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Date To (GMT)"
                  type="date"
                  dense
                  outlined
                  v-model="dados.date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="(dialog = false), clear()">
            Close
          </v-btn>
          <v-btn color="success darken-1" elevation="0" @click="retrieveAll()">
            Retrieve All
          </v-btn>
          <v-btn color="info darken-1" elevation="0" @click="retrieve()">
            Retrieve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    /*  dialog:{
        type:Boolean,
        default:false,
      } */
  },
  data: () => ({
    /* dialog: false, */
    dados: {
      sn: "",
      pn: "",
      from: "",
      date: "",
      department: "",
      arr: ""
    }
  }),
  methods: {
    clear() {
      this.dados = {
        sn: "",
        pn: "",
        from: "",
        date: "",
        department: "",
        arr: ""
      };

      this.$store.state.dialog = false;
    },
    retrieve() {
      this.$store.dispatch("filterTearDown", this.dados);

      this.$store.state.dialog = false;
    },
    retrieveAll() {
      this.$store.dispatch("getTearDown");

      /* setTimeout(() => {
          
          this.dialog = false;
        }, 500); */
      this.$store.state.dialog = false;
    },
    getAcByType(item) {
      this.$store.dispatch("getAcByType", item);

      this.dados.reg_no = "";
    }
  },
  computed: {
    dialog() {
      return this.$store.state.dialog;
    }
  }
};
</script>
