<template>
  <div>
    <v-col class="pa-0 white">
      <v-container grid-list-xs class="py-2 white mb-2">
        <v-row class=" d-flex justify-space-between align-center">
          <div class="py-2 " style="margin-bottom: 10px;">
            <v-img
              max-width="350"
              contain
              max-height="40"
              src="@/assets/images/taag.png"
            >
            </v-img>
          </div>
          <v-spacer></v-spacer>

          <v-select
            v-model="defaultLanguage"
            :items="idiomas"
            attach
            dense
            style="max-width:200px;"
            outlined
            class="mt-5 mr-5"
            solo
            flat
            label="Idioma"
            hint="Idioma"
            @change="mudaidioma(defaultLanguage)"
          ></v-select>

          {{ timeNow }}

          <v-col
            class="pa-0 ma-0 ml-10 justify-center text-center align-center"
          >
            <p>
              Josemar Lourenço
              <v-btn
                small
                color="red"
                @click="$store.dispatch('logout'), $router.push('/login')"
                text
                ><v-icon class="mr-2">mdi-logout</v-icon
                >{{ $t("logout") }}</v-btn
              >
            </p>
          </v-col>
          <div class="py-2 " style="margin-bottom: 15px;"></div>
          <div class="py-2 " style="margin-bottom: 15px;">
            <v-img
              max-width="120"
              contain
              max-height="120"
              src="@/assets/images/wairsoft.jpg"
            >
            </v-img>
          </div>
        </v-row>

        <div class="white" style="min-height:50px;">
          <v-container class="py-0 d-flex text-center px-0 align-center">
            <v-row class="pa-0 align-center">
              <v-btn route to="/" color="black" class="f12" text>
                <v-icon color="primary" class="mr-2 f11 t-l">mdi-home</v-icon>
                {{ $t("home") }}</v-btn
              >

              <dropdown
                class="pa-0"
                :trigger="'hover'"
                v-for="(menu, e) in menus"
                :key="menu.label"
              >
                <template slot="btn"
                  ><v-btn color="black" class="f12 pa-0 t-l mx-1" block text>
                    <v-icon color="primary" class="mr-2 f11 t-l">{{
                      menu.icon
                    }}</v-icon>
                    {{
                      e + 1 + ". " + $i18n.t(menu.label) || menu.label
                    }}</v-btn
                  ></template
                >
                <template
                  slot="body"
                  class=""
                  :trigger="'hover'"
                  :role="'sublist'"
                  :align="'right'"
                >
                  <div
                    v-for="(val1, i) in menu.val1"
                    :key="val1.label"
                    class="pa-0 d-flex"
                    elevation="0"
                  >
                    <v-btn
                      v-if="val1.val == null"
                      slot="btn"
                      class="f12 f12 pa-0  t-l"
                      route
                      :to="val1.route"
                      color="black"
                      text
                      block
                      >{{
                        e + 1 + "." + i + " " + $i18n.t(val1.label) ||
                          val1.label
                      }}</v-btn
                    >

                    <dropdown
                      v-else
                      :trigger="'hover'"
                      :role="'sublist'"
                      :align="'right'"
                    >
                      <template slot="btn">
                        <v-btn
                          slot="btn"
                          class="f12 f12 pa-0 t-l"
                          color="black"
                          text
                          block
                          >{{
                            e + 1 + "." + i + " " + $i18n.t(val1.label) ||
                              val1.label
                          }}</v-btn
                        ></template
                      >
                      <template slot="body">
                        <div
                          class="d-flex"
                          v-for="(item, i2) in val1.val"
                          :key="item.label"
                          elevation="0"
                        >
                          <v-btn
                            slot="btn"
                            class="f12 f12 pa-0 t-l"
                            route
                            :to="item.route"
                            color="black"
                            text
                            block
                            >{{
                              e +
                                1 +
                                "." +
                                i +
                                "." +
                                +i2 +
                                " " +
                                $i18n.t(item.label) || item.label
                            }}</v-btn
                          >
                        </div>
                      </template>
                    </dropdown>

                    <ul></ul>
                  </div>

                  <!-- <ul>
            
              <dropdown :trigger="'hover'" :role="'sublist'" :align="'right'">
                <template slot="btn">Sublist right</template>
                <template slot="body">Sublist content</template>
              </dropdown>
            </ul> -->
                </template>
              </dropdown>

              <v-btn route to="/tabelas" color="black" class="f12" text>
                <v-icon color="primary" class="mr-2 f11"
                  >mdi-table-multiple</v-icon
                >
                {{ $t("tables") }}</v-btn
              >
            </v-row>
          </v-container>
        </div>
      </v-container>
    </v-col>
  </div>
</template>

<script>
import Dropdown from "bp-vuejs-dropdown";
export default {
  components: {
    Dropdown
  },

  mounted() {
    this.currentDate();
    this.defaultLanguage =
      localStorage.getItem("idioma") != null ||
      localStorage.getItem("idioma") != ""
        ? localStorage.getItem("idioma")
        : "English";
  },

  methods: {
    mudaidioma(idioma) {
      /* MUDANDO DE IDIOMA */
      localStorage.setItem("idioma", idioma);

      this.$i18n.locale = localStorage.getItem("idioma");
    },
    currentDate() {
      setInterval(() => {
        var currentdate = new Date();
        var datetime =
          currentdate.getDate() +
          "/" +
          (currentdate.getMonth() + 1) +
          "/" +
          currentdate.getFullYear() +
          "  " +
          currentdate.getHours() +
          ":" +
          currentdate.getMinutes() +
          ":" +
          currentdate.getSeconds();
        this.timeNow = datetime.toString();
      }, 500);
    }
  },

  data() {
    return {
      defaultLanguage: "English",
      idiomas: ["English", "Português"],
      timeNow: null,

      menus: [
        {
          label: "fleetActivity",
          icon: "mdi-airplane",
          val1: [
            {
              label: "flightData",
              route: "/flightData",
              val: [
                { label: "acInformation", route: "/acInformation" },
                //{ label: "newLogEntry", route: "/newLogEntry" },
                { label: "acLog", route: "/aircraftLog" },
                { label: "acHoursCycles", route: "/aircraftHoursCycles" },
                { label: "oilFuelConsumption", route: "/oilFuelConsuption" },
                { label: "apuInformation", route: "/apuList" },

                { label: "newApu", route: "/newApu" },
                { label: "apuInsertionHour/Cycles", route: "/newApuLog" },
                { label: "apuHoursCycles", route: "/apuLogList" },

                { label: "atl", route: "/atl" }
              ]
            },
            {
              label: "defLog",
              route: "/acInformation",
              val: [
                //{label: "defectLogEntry", route:"/defectLogEntry"},
                { label: "defInfoSearch", route: "/defectLogInfoSearch" },
                { label: "newDmiRequest", route: "/newDmiRequest" },
                { label: "dmiRequestList", route: "/dmiRequestList" }
                /*{label: "outstandingDmiList", route:"/defectLogInfoSearch"},
                {label: "dmiStatusUpdate", route:"/"},
                {label: "signOffCopyMissingList", route:"/defectLogInfoSearch"},*/
              ]
            },
            /*{ label: "partTimes", route: "/partTimes", val:[
              { label: "D2-TBF", route: "/tbf" },
              { label: "D2-TBG", route: "/tbf" },
              { label: "D2-TBH", route: "/tbf" },
              { label: "D2-TBJ", route: "/tbf" },
              { label: "D2-TBK", route: "/tbf" },
              { label: "D2-TED", route: "/tbf" },
              { label: "D2-TEE", route: "/tbf" },
              { label: "D2-TEF", route: "/tbf" },
              { label: "D2-TEG", route: "/tbf" },
              { label: "D2-TEH", route: "/tbf" },
              { label: "D2-TEI", route: "/tbf" },
              { label: "D2-TEJ", route: "/tbf" },
              { label: "D2-TEK", route: "/tbf" },
            ] },*/
            {
              label: "cabinedefect",
              route: "/acInformation",
              val: [
                { label: "cdl", route: "/cDefectLog" },
                // { label: "openDefectsReport", route: "/tbf" },
                { label: "cabinDefectsSearch", route: "/cdefectLogList" }
                //{ label: "closeDeferredItems", route: "/tbf" },
              ]
            }
          ]
        },
        {
          label: "mtn",
          icon: "mdi-hammer-screwdriver",
          val1: [
            /*{ label: "mSchedule", 
                val: [
                  { label: "mTracking", route: "/" },
                  { label: "routePackage", route: "/" },
                  { label: "routeInformation", route: "/" },
                ], 
              },*/
            {
              label: "mProgram",
              val: [
                //{ label: "newMRItem", route: "/" },
                { label: "newTaskCard", route: "/newTaskCard" },
                /*{ label: "mrItemList", route: "/" },
                  { label: "mrComplance", route: "/" },
                  { label: "tcRevisionControl", route: "/" },
                  { label: "revisionPendingList", route: "/" },*/
                { label: "taskCardList", route: "/taskCardList" },
                /*{ label: "mrTcCrossReference", route: "/" },
                  { label: "oopForecast", route: "/" },
                  { label: "taskCadProperties*", route: "/" },*/
                { label: "tcLastDone", route: "/tcLastDone" }
              ]
            },
            {
              label: "mtCheck",
              val: [
                { label: "checkPlanning", route: "/checkPlanning" },
                { label: "checkHistory", route: "/checkHistory" },
                { label: "checkPackage", route: "/chekckPackage" },
                { label: "checkSignOff", route: "/checkSignOff" },
                { label: "checkPackagePreload", route: "/" },
                { label: "nextMtCheck", route: "/" },
                { label: "minimumPackageSetup", route: "/" },
                { label: "checkDef", route: "/" },
                { label: "accessPanelList", route: "/" }
              ]
            },
            {
              label: "Maintenance Order",
              val: [
                { label: "New Order", route: "/newMaintenanceOrder" },
                { label: "Closed Order", route: "/maintenanceOrderClosed" },
                /*{ label: "Issue Order", route: "/" },
                { label: "Outstanding Order Search", route: "/" },
                { label: "Order Status Update", route: "/" },
                { label: "Sign Off Copy Upload", route: "/" },
                { label: "Sign Off Copy Missing List", route: "/" },
                { label: "Issued Order List", route: "/" }, */
                { label: "Order Master List", route: "/maintenanceOrderList" },
             
            ]
            },/*
            { label: "mtPlanning", 
                val: [
                  { label: "planningDesktop", route: "/" },
                  { label: "repeaterAlerts", route: "/" },
                  
                 
                ],
              },*/
          ]
        },
        {
          label: "parts",
          icon: "mdi-arrange-send-to-back",
          val1: [
            {
              label: "parts",
              val: [
                { label: "newRequisitionEntry", route: "/newParts" },
                //{ label: "outstandingRequisitionEntry", route: "/acInformation" },
                { label: "requisitionHistorySearch", route: "/partsList" },
                { label: "partsSearch", route: "/partSearch" },
                { label: "partsInstallRemove", route: "/partInstalation" },
                //{ label: "lifeLimitedPartsList", route: "/" },
                { label: "installedPartsSetup", route: "/partInstalationList" },
                { label: "partsRemovedReport", route: "/partRemovedList" }
                //{ label: "partRequisitionApproval", route: "/acInformation" },
              ]
            },
            {
              label: "LLP'S",
              val: [
              { label: "New LLP APU TASK", route: "/newLLPApuTask" },
              { label: "Closed LLP APU TASK", route: "/LLPApuTaskClosed" },
              { label: "LLP APU TASK Master List", route: "/LLPApuTaskList" },
              { label: "LLP APU TASK  Closed List", route: "/LLPApuTaskListClosed" },

              { label: "New LLP ENGINE TASK", route: "/newLLPEngineTask" },
              { label: "Closed LLP ENGINE TASK", route: "/LLPEngineTaskClosed" },
              { label: "LLP ENGINE TASK Master List", route: "/LLPEngineTaskList" },
              { label: "LLP ENGINE TASK  Closed List", route: "/LLPEngineTaskListClosed" },

              { label: "New LLP PROPELLER TASK", route: "/newLLPPropellerTask" },
              { label: "Closed LLP PROPELLER TASK", route: "/LLPPropellerTaskClosed" },
              { label: "LLP PROPELLER TASK Master List", route: "/LLPPropellerTaskList" },
              { label: "LLP PROPELLER TASK  Closed List", route: "/LLPPropellerTaskListClosed" },

              { label: "New LLP LANDING GEAR TASK", route: "/newLLPLandingGearTask" },
              { label: "Closed LLP LANDING GEAR TASK", route: "/LLPLandingGearTaskClosed" },
              { label: "LLP LANDING GEAR TASK Master List", route: "/LLPLandingGearTaskList" },
              { label: "LLP LANDING GEAR TASK  Closed List", route: "/LLPLandingGearTaskListClosed" },
              ]
            },
            {
              label: "Hard Time",
              val: [
              { label: "New Hard Time", route: "/newHardTime" },
              { label: "Closed Hard Time", route: "/hardTimeClosed" },
              { label: "Hard Time Master List", route: "/hardTimeList" },
              { label: "Hard Time  Closed List", route: "/hardTimeListClosed" },
              ]
            },
            
            /*{ label:"stores", val:[
              { label: "partReceiving", route: "/flightData" },
            { label: "partsInspection", route: "/acInformation" },
            { label: "partsRapair", route: "/acInformation" },
            { label: "certTagMissingList", route: "/acInformation" },
            { label: "loanerPartsList", route: "/acInformation" },
            { label: "storesInOutReport", route: "/acInformation" },
            { label: "partsScrap", route: "/acInformation" },
            { label: "sotresShelfBinSetup", route: "/acInformation" },
            { label: "partLoanIn", route: "/acInformation" },
            { label: "lookupSapParts", route: "/acInformation" },
            { label: "sapRequisitions", route: "/acInformation" },
            { label: "partsReceivedReport", route: "/acInformation" },
            { label: "lpPartChangeReport", route: "/acInformation" },
            ]},
            { label:"configuration", val:[
              { label: "masterConfig", route: "/flightData" },
            { label: "partNumberConfig", route: "/acInformation" },
            { label: "assemblySetup", route: "/acInformation" },
            { label: "visualNha", route: "/acInformation" },
            { label: "kitTemplates", route: "/acInformation" },
            { label: "kitBuilder", route: "/acInformation" },
            { label: "flyAwayKits", route: "/acInformation" },
            { label: "warehouseSetup", route: "/acInformation" },
            
            ]},*/
          ]
        },
        {
          label: "documents",
          icon: "mdi-file-document-multiple",
          val1: [
            {
              label: "tecnicalLib",
              route: "/flightData",
              val: [{ label: "Techinical Publishing", route: "/documments" }]
            } /*
            { label: "airlineDocuments", route: "/acInformation", val:[
              {label:"repairs", route:"/manual"},
              {label:"alterations", route:"/manual"},
              {label:"workscope", route:"/manual"},
              {label:"dfdr", route:"/manual"},
              {label:"misReports", route:"/manual"},
              {label:"maintainenceControlRef", route:"/manual"},
              {label:"training", route:"/manual"},
              {label:"Audits", route:"/manual"},
              {label:"deliveryDocuments", route:"/manual"},
            ] },
            { label: "acRecords", route: "/acInformation", val:[
              {label:"missingRecords", route:"/manual"},
              
            ] },*/
          ]
        },
        {
          label: "compliance",
          val1: [
            {
              label: "AD/SB",
              route: "/flightData",
              val: [
                { label: "New AD Entry", route: "/newAdEntryPage" },
                { label: "Closed AD Entry", route: "/adEntryPageClosed" },
                //{ label: "AD Setup", route: "/manual" },
                { label: "New SB Entry", route: "/newserviceBulletin" },
                { label: "Closed SB Entry", route: "/serviceBulletinClosed" },
                /*{ label: "SB Setup", route: "/manual" },
                { label: "SB Evaluation History", route: "/manual" },
                { label: "SB Evaluation To Do List", route: "/manual" },
                //{ label: "SB Pending List", route: "/manual" }, */
                { label: "SB Master List", route: "/serviceBulletinList" },
                { label: "SB Closed List", route: "/serviceBulletinListClosed" },
                //{ label: "AD/SB Compliance", route: "/manual" },
                //{ label: "AD/SB Forest", route: "/manual" },
                { label: "AD Master List", route: "/adEntryPageList" },
                { label: "AD Closed List", route: "/adEntryPageListClosed" }
              ]
            },
            { label: "reliabilityAnalysis", route: "/acInformation", val:[
            /*{ label: "Add Tecnical Delay", route: "/acInformation" },
                { label: "APU In Flight Report", route: "/acInformation" },
                { label: "Flight Hours Per Month", route: "/acInformation" },
                { label: "IFSD Report", route: "/acInformation" },
                { label: "Reliability Report Search", route: "/acInformation" },
                { label: "ADD IDSD", route: "/acInformation" },*/
                { label: "ADD Tear Down", route: "/newTearDown" },
                { label: "Tear Down Master List", route: "/tearDownList" },
                { label: "ADD Event Incident", route: "/newEventsIncident" },
                { label: "Event Incident Master List", route: "/eventsIncidentList" },
                { label: "ADD Out and Back", route: "/newOutService" },
                { label: "Out and Back Master List", route: "/outServiceList" },
                { label: "ADD Damage", route: "/newDamageRepair" },
                { label: "Damage Master List", route: "/damageRepairList" },
                { label: "ADD Delays / Cancelation", route: "/newTechnicalDelays" },
                { label: "Delays / Cancelation Master List", route: "/technicalDelaysList" },
            ] },
            /*{ label: "adCompliance", route: "/acInformation", val:[
              {label: "airframe", route:"/airframe"},
              {label: "engine", route:"/airframe"},
              {label: "appliance", route:"/airframe"},
            ] },
            { label: "certification", route: "/acInformation", val:[
              {label: "newCertificate", route:"/airframe"},
              {label: "newTechnician", route:"/airframe"},
              {label: "assignCertificate", route:"/airframe"},
              {label: "viewCertificateAssignments", route:"/airframe"},
              {label: "search", route:"/airframe"},
              {label: "editCertificate", route:"/airframe"},
              {label: "editTechnician", route:"/airframe"},
            ] },*/
          ],
        },
        {
          label: "administration",

          icon: "mdi-apps-box",
          val1: [
            { label: "User Information Setup", route: "/users" }
            /*  { label: "Issued Order", route: "/acInformation" },
            { label: "Outstanding Order Search", route: "/acInformation" }, */
          ]
        },
        
            
         
      ]
    };
  }
};
</script>

<style></style>
