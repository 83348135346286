<template>
  <div>
    <cabeca></cabeca>
    <adEntryPageClosedFilter
      :dialog="this.$store.state.dialog"
    ></adEntryPageClosedFilter>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>AD ENTRY PAGE CLOSED LIST</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>

          <v-row class="justify-end">
            <v-btn
              color="info"
              class="mr-2 mt-1"
              depressed
              small
              route
              to="/adEntryPageClosed"
              >Close New Ad Entry Page
            </v-btn>
            <v-btn
              color="primary"
              class="mr-10"
              icon
              @click="$store.state.dialog = true"
            >
              <v-icon>mdi-filter-menu</v-icon></v-btn
            >
            <download-excel
              header="adEntryPage INFORMATION"
              name="adEntryPageInformation"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card flat height="350">
          <v-data-table
            height="300"
            :search="search"
            fixed-header
            dense
            :headers="headers"
            :items="$store.state.closed_ad"
            :items-per-page="10"
          >
            <template v-slot:item.options="{ item, index }">
              <v-btn
                @click="editar(item)"
                small
                icon
                class="mx-2"
                color="orange"
                ><v-icon>mdi-clipboard-edit</v-icon></v-btn
              >
              <v-btn
                @click="remove(index, item)"
                small
                icon
                class="mx-2"
                color="red white--text"
                ><v-icon>mdi-trash-can</v-icon></v-btn
              >
            </template>

            <template v-slot:item.created_at="{ item }">
              {{ item.created_at.toString().split(" ")[0] }}
            </template>
            <template v-slot:item.due_date="{ item }">
              {{
                getDate(
                  item.data_discovery,
                  (item.due_date ? item.due_date : item.mel)
                    .toString()
                    .split(" ")[0]
                )
              }}
            </template>

            <template v-slot:item.file="{ item }">
              <v-btn
                :disabled="
                  !item.file || item.file == 'undefined' || item.file == 'null'
                "
                color="success"
                icon
                :href="$store.state.path2 + item.file"
                target="_blank"
                ><v-icon>mdi-file</v-icon></v-btn
              >
              <!--  {{(item.op_impact=='1'||item.op_impact=='true')?'Yes':'No'}} -->
              <!--  <v-checkbox disabled  class="pa-0 ma-0 "  :value="item.action_rii=='1'?true:false"></v-checkbox> -->
            </template>

            <template v-slot:item.certificate="{ item }">
              <v-btn
                :disabled="item.certificate == null ? true : false"
                color="success"
                small
                icon
                target="_blank"
                link
                :href="item.certificate"
                ><v-icon>mdi-file-cad</v-icon></v-btn
              >
            </template>
            <template v-slot:item.status="{ item }">
              {{ item.status == "true" ? "Yes" : "No" }}
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
import adEntryPageClosedFilter from "@/components/adEntryPageClosedFilter";
export default {
  components: {
    cabeca,
    adEntryPageClosedFilter
  },

  data() {
    return {
      search: null,
      dialog: false,
      headers: [
        {
          text: "OPTIONS",
          value: "options",
          class: "info white--text",
          width: 140
        },

        { text: "AC", value: "reg_no", class: "info white--text", width: 100 },
        {
          text: "AD TYPE",
          value: "ad_type",
          class: "info white--text",
          width: 120
        },
        {
          text: "AMENDMENT",
          value: "amendment",
          class: "info white--text",
          width: 100
        },
        {
          text: "EFF. DATE",
          value: "effective_date",
          class: "info white--text",
          width: 120
        },
        {
          text: "SERIAL Nº",
          value: "seria_no",
          class: "info white--text",
          width: 90
        },
        {
          text: "SUBJECT",
          value: "subject",
          class: "info white--text",
          width: 90
        },
        {
          text: "METHOD OF COMPLIANCE",
          value: "position",
          class: "info white--text",
          width: 120
        },

        {
          text: "AD DESCRIPTION",
          value: "ad_description",
          class: "info white--text",
          width: 120
        },
        {
          text: "REMARKS",
          value: "remarks",
          class: "info white--text",
          width: 90
        },
        {
          text: "COMPLETED DATE",
          value: "complete_date",
          class: "info white--text",
          width: 90
        },
        {
          text: "CLOSE DATE",
          value: "close_date",
          class: "info white--text",
          width: 120
        },
        {
          text: "AC TIME",
          value: "ac_time",
          class: "info white--text",
          width: 90
        },
        {
          text: "AC CYCLES",
          value: "ac_cycles",
          class: "info white--text",
          width: 90
        },
        {
          text: "REPETITIVE",
          value: "status",
          class: "info white--text",
          width: 90
        }
      ]
    };
  },

  computed: {
    desserts() {
      return this.$store.state.adEntryPage;
    }
  },

  methods: {
    percentagemFromDates(s, e) {
      var start = new Date(s);
      var end = new Date(e);
      var today = new Date();

      //use Math.abs to avoid sign
      var q = today - start;
      var d = end - start;

      /*  console.info('Porcentagem', Math.round(((q/d))*100)) */
      return (q / d) * 100;
    },
    getColor(discovery, dataFinal) {
      if (this.percentagemFromDates(discovery, dataFinal) >= 100) return "red";
      else if (
        this.percentagemFromDates(discovery, dataFinal) < 100 &&
        this.percentagemFromDates(discovery, dataFinal) >= 60
      )
        return "orange";
      else return "green";
    },

    getDate(discovery, data) {
      let dataFinal = data;
      var date = new Date(discovery);
      let d = 0;
      if (data == "A") {
        d = 1;
      } else if (data == "B") {
        d = 3;
      } else if (data == "C") {
        d = 10;
      } else if (data == "D") {
        d = 120;
      } else {
        date = new Date(data);
      }
      date.setDate(date.getDate() + d);
      var mes = date.getMonth() + 1;
      dataFinal =
        date.getFullYear() +
        "-" +
        (mes.toString().length < 2 ? "0" + mes : mes) +
        "-" +
        (date.getDate().toString().length < 2
          ? "0" + date.getDate()
          : date.getDate());

      console.log(data, dataFinal, d);
      return dataFinal || "";
    },

    editar(item) {
      this.$store.state.editing = true;
      this.$store.state.editingItem = item;
      this.$store.state.to = "/newadEntryPage";
      this.$router.push("newadEntryPage");
    },
    vedadeiro(valor) {
      return valor;
    },
    // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(this.$store.state.path + "adEntryPage/" + item.id.toString())
        .then(function() {
          store.state.adEntryPage.splice(index, 1);
          Swal.fire("Success!", " data removed successfuly.", "success");
        })
        .catch(function(error) {
          Swal.fire("Oops...", "Unable to delete. ", "error");
          console.info(error);
        });
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it"
      }).then(result => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This datet is is safe :)", "error");
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch("closed_ad");
    this.$store.dispatch("getAircraftInformation");

    this.$store.dispatch("defectType");

    this.$store.state.dialog = true;
  }
};
</script>

<style></style>
