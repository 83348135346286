<template>
    <div>

      <cabeca></cabeca>
  <v-container grid-list-xs>
      <v-card
     class="mx-auto pa-2 px-10 mb-10"
     outlined
     color="white" >

     <v-card-title class="px-0" primary-title>
         AIRCRAFT MAINTENACE SCHEDULE

         <v-row class="justify-end">
            <v-btn
              color="info"
              class="mr-2 mt-1"
              depressed
              small
              route
              to="/newMaintenanceSchedule"
              >Add New Schedule
            </v-btn>
            <v-btn
              color="primary"
              class="mr-10"
              icon
              @click="$store.state.dialog = true"
            >
              <v-icon>mdi-filter-menu</v-icon></v-btn
            >
            <download-excel
              header="Maintenance Schedule"
              name="Maintenance Schedule"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
     </v-card-title>
  <DayPilotScheduler :config="config" ref="schedulerRef" />
</v-card>

</v-container> 
</div>
</template>

<script setup>
import cabeca from "@/components/cabeca";

import { DayPilot, DayPilotScheduler } from 'daypilot-pro-vue';
import { ref, reactive, onMounted } from 'vue';

const d = DayPilotScheduler;
const c = cabeca;



c.x;
d.x;
const config = reactive({
      timeHeaders: [{groupBy: "Month"}, {groupBy: "Day", format: "d"}],
      scale: "Day",
      days: DayPilot.Date.today().daysInYear(),
      startDate: DayPilot.Date.today().firstDayOfYear(),
      timeRangeSelectedHandling: "Enabled",
      eventHeight: 40,
      durationBarVisible: false,
      eventBorderRadius: 20,
      rowMarginTop: 2,
      rowMarginBottom: 2,
      rowHeaderColumns: [
        {title: "Name", display: "name"},
        
      ],
      resources:[],
      events:[]
      ,
      onTimeRangeSelected: async args => {
        const scheduler = schedulerRef.value?.control;
        const modal = await DayPilot.Modal.prompt("Create a new schedule:", "");
        scheduler.clearSelection();
        if (modal.canceled) {
          return;
        }
        scheduler.events.add({
          start: args.start,
          end: args.end,
          id: DayPilot.guid(),
          resource: args.resource,
          text: modal.result,
          color: "#93c47d"
        });
        const data = reactive({
          start: args.start,
          end: args.end,
          reg_no: args.resource,
          
          description: modal.result,
          
        });
        var form_data = new FormData();

  for (var key in this.dados) {
    form_data.append(key, data[key] || "");
  }
  const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + window.sessionStorage.getItem("token")
        }
      };
        
  let axios = require("axios");

     axios
    .post("http://127.0.0.1:8000/api/maintenanceSchedule",form_data, config)
    .then(function(response) {
      if (response.status == "200")
      console.info("Chedule create sucess:");
      
      
    })
    .catch(function(error) {
      
      console.info(error);
    });


        
        
      },
      eventMoveHandling: "Update",
      onEventMoved: args => {
        args.control.message("Event moved: " + args.e.data.text);
      },
      eventResizeHandling: "Update",
      onEventResized: args => {
        args.control.message("Event resized: " + args.e.data.text);
      },
      eventClickHandling: "Enabled",
      onEventClicked: args => {
        args.control.message("Event clicked: " + args.e.data.text);
      },
      eventHoverHandling: "Disabled",
      treeEnabled: true,
      onBeforeRowHeaderRender: args => {
        if (args.row.data.status === "locked") {
          args.row.columns[2].areas = [
            {
              top: "calc(50% - 10px)",
              left: "calc(50% - 10px)",
              width: 20,
              height: 20,
              symbol: "icons/daypilot.svg#padlock",
              fontColor: "#999999",
              visibility: "Visible",
            }
          ];
        }
      },
      onBeforeEventRender: args => {
        args.data.backColor = args.data.color;
        args.data.borderColor = "darker";
        args.data.fontColor = "#ffffff";
        args.data.areas = [
          {
            top: 10,
            right: 6,
            width: 20,
            height: 20,
            symbol: "icons/daypilot.svg#minichevron-down-4",
            fontColor: "#999999",
            backColor: "#f9f9f9",
            borderRadius: "50%",
            visibility: "Visible",
            action: "ContextMenu",
            padding: 1,
            style: "border: 2px solid #ccc; cursor:pointer;"
          }
        ];
      },
      contextMenu: new DayPilot.Menu({
        items: [
          {
            text: "Delete",
            onClick: args => {
              const e = args.source;
              const scheduler = schedulerRef.value?.control;
              scheduler.events.remove(e);
              scheduler.message(e.data.id);
            }
          },
          {
            text: "-"
          },
          
           
          {
            text: "Red",
            icon: "icon icon-red",
            color: "#dd7e6b",
            onClick: args => {
              updateColor(args.source, args.item.color);
            }
          },
        ]
      })
    }
);
const schedulerRef = ref(null);

const loadDate = () => {
  
        
        let axios = require("axios");
           axios
          .get("http://127.0.0.1:8000/api/maintenanceSchedule", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            config.events  =response.data;
            
            
          })
          .catch(function(error) {
            
            console.info(error);
          });

};



const loadResources = () => {
  const resources = [
    {
      name: "DASH-800", id: "1", expanded: true, children: [
        {name: "D2-TEG", id: "D2-TEG", },
        {name: "D2-TBG", id: "TBG", },
        
      ]
    },
    
  ];
  
  
  config.resources = resources;
};

const updateColor = (e, color) => {
  const scheduler = schedulerRef.value?.control;
  e.data.color = color;
  scheduler.events.update(e);
  scheduler.message("Color updated");
};

onMounted(() => {
  
 
  loadResources();
  //loadReservations();
  loadDate();

 
  
const scheduler = schedulerRef.value?.control;
  scheduler.message(config.resources);
  scheduler.scrollTo(DayPilot.Date.today().firstDayOfMonth());
});


</script>