import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    logged: true,
    loading: false,
    dialog: true,
    editing: false,
    editingItem: null,
    to: "",
    permission: false,
    partInstalation: [],
    allInstalation: [],
    rep: [],
    partRemoved: [],
    apu: [],
    apuLog: [],
    cabine: [],
    cDefect: [],
    parts: [],
    partType: [],
    user: {},
    defectLog: [],
    users: [],
    acInformation: [],
    aircraftLog: [],
    atl: [],
    defectType: [],
    aircraftType: [],
    taskCard: [],
    dmiRequest: [],
    dmiRequestO: [],
    ac_accumulated_hours_and_cycles: [],
    manual: [],
    manualRequest: [],
    fleetRequest: [],
    manualType: [],
    documments: [],
    showDocumments: [],
    
    taskRequest: [],
    taskMasterRequest: [],
    taskMaintRequest: [],
    hardTime: [],
    hardTimeClosed: [],
    hardTimeClose: [],
    hardTimeSelectExcell: [],
    closed_: [],
    maintenanceOrderClosed: [],
    maintenanceOrder: [],
    
    ata: [],
    ataSection: [],
    ataSection2: [],
    adEntryPage: [],
    taskAdRequest: [],
    adEntryPageClosed: [],
    adEntryPageClose: [],
    adEntryPageSelectExcell: [],
    closed_ad: [],
    folder: [],
    ad_type: [
      { ad_type: "Airframe AD" },
      { ad_type: "Engine AD" },
      { ad_type: "Appliance AD" }
    ],
    sb_type: [
      { sb_type: "Airframe" },
      { sb_type: "Engine" },
      { sb_type: "Appliance" }
    ],
    event_type:[{event_type:"OPC"},
      {event_type:"DET"},
      {event_type:"GVI"},
      {event_type:"OPC"},
      {event_type:"FNC"},
      {event_type:"DIS"},
      {event_type:"RST"},
      {event_type:"SVC"}
    ],
    type_control:[{type_control:"HT"},
      {type_control:"LLP"},
      {type_control:"LLP RST"},

    ],
    serviceBulletin: [],
    taskSbRequest: [],
    taskApuRequest: [],
    taskEngineRequest: [],
    taskPropellerRequest: [],
    taskLandingGearRequest: [],
    serviceBulletinClosed: [],
    serviceBulletinClose: [],
    serviceBulletinSelectExcell: [],
    closed_sb: [],
    technicalDelays: [],
    outService: [],
    tearDown: [],
    eventsIncident: [],
    damageRepair: [],
    maintenanceSchedule:[],
    masterLD: [],
    masterLDClosed: [],
    masterLDClose: [],
    masterLDSelectExcell: [],
    closed_master: [],
    reportLND:[],
    LLPApuTask:[],
    LLPApuTaskClosed:[],
    LLPEngineTask:[],
    LLPEngineTaskClosed:[],
    LLPPropellerTask:[],
    LLPPropellerTaskClosed:[],
    LLPLandingGearTask:[],
    LLPLandingGearTaskClosed:[],
    path: "https://auth.camosoftwareapi.com/api/",
    path2: "https://auth.camosoftwareapi.com/"

    //https://auth.camosoftwareapi.com
    //http://127.0.0.1:8000
  },
  mutations: {
    /*  atl(context, item){
      defectLog.defectLog.push(item);
    } */

    logout(state) {
      state.logged = false;
    }
  },
  actions: {
    logout(state) {
      window.sessionStorage.setItem("token", "");
      state.defectLog = [];
      (state.user = {}), (state.users = []);
      state.aircraftType = [];
      state.commit("logout");
    },
    async logged(store) {
      store.dispatch("loading");
      let axios = require("axios");
      await axios
        .get(store.state.path + "logout", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.state.user = response.data.data;
          /*  console.info(response); */
          store.dispatch("nloading");
        })
        .catch(function(error) {
          // handle error
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to Permission.",
            "error"
          );
          console.info(error);
        });
    },
    async showDocumments(store, dados) {
      let axios = require("axios");

      await axios
        .get(store.state.path + "getDocumments/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.state.showDocumments = response.data;

          console.info(response);
        });
    },
    async getFolder(store) {
      if (store.state.folder.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "folder", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.folder = response.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get folder.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getManual(store) {
      if (store.state.manual.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "manual", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.manual = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get Manual.",
              "error"
            );
            console.info(error);
          });
      }
    },
    getManualRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "manualRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.manualRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Manual.",
            "error"
          );
          console.info(error);
        });
    },
    getFleetRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "fleetRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.fleetRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Fleet.",
            "error"
          );
          console.info(error);
        });
    },

    async getManualType(store) {
      if (store.state.manualType.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "manualType", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.manualType = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get Manual Type.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getDocumments(store) {
      if (store.state.documments.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "documments", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.documments = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get Documments.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async userLogged(store) {
      store.dispatch("loading");
      let axios = require("axios");
      await axios
        .get(store.state.path + "userLogged", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          window.sessionStorage.setItem("username", response.data.data.name);
          /*  console.info(response); */
          store.dispatch("nloading");
        })
        .catch(function(error) {
          // handle error
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to Permission.",
            "error"
          );
          console.info(error);
        });
    },
    async permission(store) {
      store.dispatch("loading");
      let axios = require("axios");
      await axios
        .get(store.state.path + "checkPermissions", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.state.permission = response.data.canAccessAdmin;
          /*  console.info(response); */
          store.dispatch("nloading");
        })
        .catch(function(error) {
          // handle error
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to Permission.",
            "error"
          );
          console.info(error);
        });
    },
    ataSection(store, dados = { index: "00", d: 0 }) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "ataSection/" + dados.index, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          if (!dados.d) store.state.ataSection = response.data;
          else store.state.ataSection2 = response.data;

          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get ata Section.",
            "error"
          );
          console.info(error);
        });
    },
    defectType(store) {
      if (store.state.defectType.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        axios
          .get(store.state.path + "defectType", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.defectType = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get deft types.",
              "error"
            );
            console.info(error);
          });
      }
    },
    getAtl(store) {
      /* if (store.state.atl.length <= 0) { */
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "atl", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.atl = response.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Atl.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
      /* } */
    },
    filterAircraftLog(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "atlFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.atl = response.data.data;

          /*  console.info(response.data); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Atl.",
            "error"
          );
          console.info(error);
        });
    },
    filterDefectLog(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "defectLogFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.defectLog = response.data.data;

          /*  console.info(response.data); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Defect Log.",
            "error"
          );
          console.info(error);
        });
    },
    getDefectLog(store) {
      /*  if (store.state.defectLog.length <= 0) { */

      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "defectLog", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.defectLog = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get aircraftInformations.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
      /* } */
    },
    getAta(store) {
      if (store.state.ata.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "ata", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.ata = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the ata.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getTaskCard(store) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "taskCard", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.taskCard = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the ata.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getDmiRequestO(store) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "dmiRequestO", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.dmiRequestO = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the DMI.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getDmiRequest(store) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "dmiRequest", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.dmiRequest = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the ata.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterDMI(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "filterDMI", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.dmiRequestO = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the DMIs.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filteACHoursCycles(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(
          store.state.path + "ac_accumulated_hours_and_cyclesFilter",
          dados,
          {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          }
        )
        .then(function(response) {
          store.dispatch("nloading");
          store.state.ac_accumulated_hours_and_cycles = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the AC Hours and Cycles.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getACHoursCycles(store) {
      const axios = require("axios");

      store.dispatch("loading");

      axios
        .get(store.state.path + "ac_accumulated_hours_and_cycles", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(response => {
          store.state.ac_accumulated_hours_and_cycles = response.data.data;
          store.dispatch("nloading");
        });

      store.dispatch("nloading");
    },
    getAcType(store) {
      if (store.state.aircraftType.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "acType", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.aircraftType = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the ata.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    async getAircraftInformation(store) {
      /* if (store.state.acInformation.length <= 0) { */
      store.dispatch("loading");

      let axios = require("axios");

      await axios
        .get(store.state.path + "acInformation", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.acInformation = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get aircraftInformations.",
            "error"
          );

          console.info(error);
        })
        .then(function() {
          return "done!";
        });

      return "done";

      /* } */
    },
    getAcByType(store, type) {
      /* if (store.state.acInformation.length <= 0) { */
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .post(
          store.state.path + "acByType",
          { config: type },
          {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          }
        )
        .then(function(response) {
          store.dispatch("nloading");
          store.state.acInformation = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get aircraftInformations.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
      /* } */
    },
    getCabineDefectLog(store) {
      /* if (store.state.atl.length <= 0) { */
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .get(store.state.path + "cabineDefectLog", {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.cabine = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Cabine Defect Log.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
      /* } */
    },
    getCabine(store) {
      if (store.state.cabine.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "cabineDefectLog", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.cabine = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the Cabine.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getPartInstalation(store) {
      if (store.state.partInstalation.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "partInstalation", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.partInstalation = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the part type.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getAllInstalation(store) {
      if (store.state.allInstalation.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "allInstalation", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.allInstalation = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the part all Instalation.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getPartRemoved(store) {
      if (store.state.partRemoved.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "allRemoved", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.partRemoved = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the all Removed.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    async getApu(store) {
      if (store.state.apu.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "apu", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.apu = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get APU.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getLLPApuTask(store) {
      if (store.state.LLPApuTask.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPApuTask", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPApuTask = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP APU TASK.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getLLPEngineTask(store) {
      if (store.state.LLPEngineTask.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPEngineTask", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPEngineTask = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP Engine TASK.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getLLPApuTaskClosed(store) {
      if (store.state.LLPApuTaskClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPApuTaskClosed", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPApuTaskClosed = response.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP APU TASK CLOSED.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getLLPEngineTaskClosed(store) {
      if (store.state.LLPEngineTaskClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPEngineTaskClosed", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPEngineTaskClosed = response.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP ENGINE TASK CLOSED.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getLLPPropellerTaskClosed(store) {
      if (store.state.LLPPropellerTaskClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPPropellerTaskClosed", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPPropellerTaskClosed = response.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP Propeller TASK CLOSED.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getLLPPropellerTask(store) {
      if (store.state.LLPPropellerTask.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPPropellerTask", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPPropellerTask = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP Propeller TASK.",
              "error"
            );
            console.info(error);
          });
      }
    },
    filterLLPPropellerTask(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPPropellerTaskFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPPropellerTask = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP Propeller TASK Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterLLPPropellerTaskClosed(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPPropellerTaskClosedFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPPropellerTaskClosed = response.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP Propeller TASK CLOSED Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },


    async getLLPLandingGearTaskClosed(store) {
      if (store.state.LLPLandingGearTaskClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPLandingGearTaskClosed", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPLandingGearTaskClosed = response.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP Landing Gear TASK CLOSED.",
              "error"
            );
            console.info(error);
          });
      }
    },
    async getLLPLandingGearTask(store) {
      if (store.state.LLPLandingGearTask.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        await axios
          .get(store.state.path + "LLPLandingGearTask", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.LLPLandingGearTask = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get LLP Landing Gear TASK.",
              "error"
            );
            console.info(error);
          });
      }
    },
    filterLLPLandingGearTask(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPLandingGearTaskFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPLandingGearTask = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP Landing Gear TASK Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterLLPLandingGearTaskClosed(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPLandingGearTaskClosedFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPLandingGearTaskClosed = response.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP Landing Gear TASK CLOSED Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getApuLog(store) {
      if (store.state.apuLog.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");
        axios
          .get(store.state.path + "apuLog", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.state.apuLog = response.data.data;
            /*  console.info(response); */
            store.dispatch("nloading");
          })
          .catch(function(error) {
            // handle error
            store.dispatch("nloading");
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get APU LOG.",
              "error"
            );
            console.info(error);
          });
      }
    },
    filterApu(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "apuFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.apu = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Apu log Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterLLPApuTask(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPapuTaskFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPApuTask = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP APU TASK Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterLLPApuTaskClosed(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPapuTaskClosedFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPApuTaskClosed = response.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP APU TASK CLOSED Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },

    filterLLPEngineTask(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPEngineTaskFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPEngineTask = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP Engine TASK Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterLLPEngineTaskClosed(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "LLPEngineTaskClosedFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.LLPEngineTaskClosed = response.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the LLP Engine TASK CLOSED Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    filterApuLog(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "apuLogFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.apuLog = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Apu log Filter.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    },
    getParts(store) {
      if (store.state.parts.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "parts", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.parts = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the parts.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    filterParts(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "partsFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.parts = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Part .",
            "error"
          );
          console.info(error);
        });
    },
    getServiceBulletin(store) {
      if (store.state.serviceBulletin.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "serviceBulletin", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.serviceBulletin = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the serviceBulletin.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    serviceBulletinSelectExcell(store) {
      if (store.state.serviceBulletinSelectExcell.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "serviceBulletinSelectExcell", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.serviceBulletinSelectExcell = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the serviceBulletinSelectExcell.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    closed_sb(store) {
      if (store.state.closed_ad.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "serviceBulletinClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.closed_sb = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the serviceBulletin.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getServiceBulletinClose(store) {
      if (store.state.serviceBulletinClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "serviceBulletinClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.serviceBulletinClosed = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the serviceBulletinClose.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    filterServiceBulletin(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "serviceBulletinFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.serviceBulletin = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get Part .",
            "error"
          );
          console.info(error);
        });
    },
    getTechnicalDelays(store) {
      if (store.state.technicalDelays.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "technicalDelays", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.technicalDelays = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the technicalDelays.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    filterTechnicalDelays(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "technicalDelaysFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.technicalDelays = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get technicalDelays .",
            "error"
          );
          console.info(error);
        });
    },

    getOutService(store) {
      if (store.state.outService.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "outService", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.outService = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the outService.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    filterOutService(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "outServiceFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.outService = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get outService .",
            "error"
          );
          console.info(error);
        });
    },

    getTearDown(store) {
      if (store.state.tearDown.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "tearDown", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.tearDown = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the tearDown.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    filterTearDown(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "tearDownFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.tearDown = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get tearDown .",
            "error"
          );
          console.info(error);
        });
    },
    getEventsIncident(store) {
      if (store.state.eventsIncident.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "eventsIncident", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.eventsIncident = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the eventsIncident.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    filterEventsIncident(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "eventsIncidentFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.eventsIncident = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get eventsIncident .",
            "error"
          );
          console.info(error);
        });
    },
    getDamageRepair(store) {
      if (store.state.damageRepair.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "damageRepair", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.damageRepair = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the damageRepair.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    
    filterDamageRepair(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "damageRepairFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.damageRepair = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get damageRepair .",
            "error"
          );
          console.info(error);
        });
    },
    getMaintenanceSchedule(store) {
      if (store.state.maintenanceSchedule.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "maintenanceSchedule", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.maintenanceSchedule = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the maintenanceSchedule.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    
    filterMaintenanceSchedule(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "maintenanceScheduleFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.maintenanceSchedule = response.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get maintenanceSchedule .",
            "error"
          );
          console.info(error);
        });
    },
    getReported(store) {
      if (store.state.rep.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "reported", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.rep = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the Reported.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getPartType(store) {
      if (store.state.partType.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "partType", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.partType = response.data.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the part type.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getAircraftConfig(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "aircraftConfig/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.aircraftConfig = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Aircraft Config.",
            "error"
          );
          console.info(error);
        });
    },
    getDmiRequestReg(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "dmiRequestReg/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.dmiRequestReg = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the DMI.",
            "error"
          );
          console.info(error);
        });
    },
    filterHardTime(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "hardTimeFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.hardTime = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get hardTime .",
            "error"
          );
          console.info(error);
        });
    },
    filterMasterLD(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "masterLDFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.masterLD = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get masterLD .",
            "error"
          );
          console.info(error);
        });
    },
    getMasterLD(store) {
      if (store.state.masterLD.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "masterLD", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.masterLD = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the masterLD.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    masterLDSelectExcell(store) {
      if (store.state.masterLDSelectExcell.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "masterLDSelectExcell", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.masterLDSelectExcell = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the masterLDSelectExcell.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    masterLDClosed(store) {
      if (store.state.masterLDClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "masterLDClosed", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.masterLDClosed = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the masterLDClosed.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getmasterLDClose(store) {
      if (store.state.masterLDClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "masterLDClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.masterLDClosed = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the masterLDClose.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getReportLND(store) {
      if (store.state.reportLND.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "reportLND", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.reportLND = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the reportLND.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    filterAdEntryPage(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "adEntryPageFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.adEntryPage = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get adEntryPage .",
            "error"
          );
          console.info(error);
        });
    },
    filterMaintenanceOrder(store, dados) {
      store.dispatch("loading");
      let axios = require("axios");

      axios
        .post(store.state.path + "maintenanceOrderFilter", dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");
          store.state.maintenanceOrder = response.data.data;

          console.info(response.data);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get maintenanceOrder .",
            "error"
          );
          console.info(error);
        });
    },
    getHardTime(store) {
      if (store.state.hardTime.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "hardTime", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.hardTime = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the hardTime.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    hardTimeSelectExcell(store) {
      if (store.state.hardTimeSelectExcell.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "hardTimeSelectExcell", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.hardTimeSelectExcell = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the hardTimeSelectExcell.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    closed_(store) {
      if (store.state.closed_.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "hardTimeClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.closed_ = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the hardTime.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    closed_master(store) {
      if (store.state.closed_master.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "masterLDClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.closed_master = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the masterLD.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    hardTimeClosed(store) {
      if (store.state.hardTimeClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "hardTimeClosed", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.hardTimeClosed = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the hardTimeClosed.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getHardTimeClose(store) {
      if (store.state.hardTimeClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "hardTimeClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.hardTimeClosed = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the hardTimeClose.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getAdEntryPage(store) {
      if (store.state.adEntryPage.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "adEntryPage", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.adEntryPage = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the adEntryPage.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    adEntryPageSelectExcell(store) {
      if (store.state.adEntryPageSelectExcell.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "adEntryPageSelectExcell", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.adEntryPageSelectExcell = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the adEntryPageSelectExcell.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    closed_ad(store) {
      if (store.state.closed_ad.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "adEntryPageClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.closed_ad = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the adEntryPage.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getAdEntryPageClose(store) {
      if (store.state.adEntryPageClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "adEntryPageClose", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.adEntryPageClosed = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the adEntryPageClose.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getMaintenanceOrder(store) {
      if (store.state.maintenanceOrder.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "maintenanceOrder", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.maintenanceOrder = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the maintenanceOrder.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    maintenanceOrderClosed(store) {
      if (store.state.maintenanceOrderClosed.length <= 0) {
        store.dispatch("loading");
        let axios = require("axios");

        axios
          .get(store.state.path + "maintenanceOrderClosed", {
            headers: {
              Authorization: "Bearer " + window.sessionStorage.getItem("token")
            }
          })
          .then(function(response) {
            store.dispatch("nloading");
            store.state.maintenanceOrderClosed = response.data;
            /*  console.info(response); */
          })
          .catch(function(error) {
            store.dispatch("nloading");
            // handle error
            const Swal = require("sweetalert2");
            Swal.fire(
              "Oops...",
              "There was an erro when trying to get the maintenanceOrderClosed.",
              "error"
            );
            console.info(error);
          })
          .then(function() {
            // always executed
          });
      }
    },
    getTaskRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskMasterRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskMasterRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskMasterRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the TaskMaster.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskAdRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskAdRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskAdRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task Ad.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskSbRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskSbRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskSbRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task SB.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskApuRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskApuRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskApuRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task APU.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskEngineRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskEngineRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskEngineRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task Engine.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskPropellerRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskPropellerRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskPropellerRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task Propeller.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskLandingGearRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskLandingGearRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskLandingGearRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task Landing Gear.",
            "error"
          );
          console.info(error);
        });
    },
    getTaskMaintRequest(store, dados) {
      store.dispatch("loading");

      let axios = require("axios");

      axios
        .get(store.state.path + "taskMaintRequest/" + dados, {
          headers: {
            Authorization: "Bearer " + window.sessionStorage.getItem("token")
          }
        })
        .then(function(response) {
          store.dispatch("nloading");

          store.state.taskMaintRequest = response.data.data;

          console.info(response);
        })
        .catch(function(error) {
          store.dispatch("nloading");
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the Task Maint.",
            "error"
          );
          console.info(error);
        });
    },

    loading(store) {
      store.state.loading = true;
    },
    nloading(store) {
      store.state.loading = false;
    },
    clearEdit(store) {
      store.state.editing = false;
      store.state.editingItem = null;
      store.state.to = "";
    }
  },
  modules: {}
});
