<template>
  <div>
    <v-dialog v-model="$store.state.loading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading... Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {}
};
</script>
